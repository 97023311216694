import { Pipe, PipeTransform } from '@angular/core';
import { DatesService } from '../services/dates.service';

@Pipe({
  name: 'month',
})
export class MonthPipe implements PipeTransform {
  constructor(private dateService: DatesService) {}

  transform(value: unknown, ...args: unknown[]): unknown {
    const months = this.dateService.getMonths();
    const month = months.find((m) => m.value === value);
    return month ? month.name : '';
  }
}
