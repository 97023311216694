import { Injectable } from '@angular/core';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {

  private readonly TAG = 'KeyboardService -';

  private keyboardStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private platform: Platform) {
    console.log(this.TAG, 'load');
  }

  initService() {
    if (this.platform.is('hybrid')) {
      this.setListeners();
    }
  }

  closeService() {
    if (this.platform.is('hybrid')) {
      this.clearListeners();
    }
  }

  getKeyboardStatus() {
    return this.keyboardStatus;
  }

  private setListeners() {
    console.log(this.TAG, 'setListeners');

    Keyboard.addListener('keyboardWillShow', (info: KeyboardInfo) => {
      console.log(this.TAG, 'keyboard will show with height', info.keyboardHeight);
      this.keyboardStatus.next(true);
    });

    Keyboard.addListener('keyboardDidShow', (info: KeyboardInfo) => {
      console.log(this.TAG, 'keyboard did show with height', info.keyboardHeight);
    });

    Keyboard.addListener('keyboardWillHide', () => {
      console.log(this.TAG, 'keyboard will hide');
      this.keyboardStatus.next(false);
    });

    Keyboard.addListener('keyboardDidHide', () => {
      console.log(this.TAG, 'keyboard did hide');
    });
  }

  private clearListeners() {
    Keyboard.removeAllListeners();
  }

}
