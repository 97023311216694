import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpdateServicioContable } from 'src/app/countries/pe/services/dto/update-servicio-contable.dto';
import { UpdateServicioFacturacion } from 'src/app/countries/pe/services/dto/update-servicio-facturacion.dto';
import { ResponseI } from 'src/app/interfaces/response.interface';
import { IUserData } from 'src/app/services/user/user-data.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsuarioTSService {
  private API_URL_AR = environment.apiUrl.ar;
  private API_URL_PE = environment.apiUrl.pe;
  private API_URL_UY = environment.apiUrl.ar;



  constructor(private http: HttpClient) {}

  updateCUIT(cuit: string) {
    return this.http.put<ResponseI<any>>(`${this.API_URL_AR}/usuarioTS/update-cuit`, { cuit });
  }

  updateRUT(rut: string) {
    return this.http.put<ResponseI<any>>(`${this.API_URL_UY}/usuarioTS/update-rut`, { rut });
  }

  updateCI(ci: string) {
    return this.http.put<ResponseI<any>>(`${this.API_URL_UY}/usuarioTS/update-ci`, { ci });
  }

  updateServicioContable(dto: UpdateServicioContable) {
    return this.http.put<ResponseI<IUserData>>(`${this.API_URL_PE}/usuarioTS/update-servicio-contable`, dto);
  }

  updateServicioFacturacion(dto: UpdateServicioFacturacion) {
    return this.http.put<ResponseI<IUserData>>(`${this.API_URL_PE}/usuarioTS/update-datos-servicio-facturacion`, dto);
  }

  newClaveFiscal(clave: string) {
    return this.http.put<ResponseI<IUserData>>(`${this.API_URL_PE}/clavefiscalinternacional/new`, {
      clave,
      tipo_clave_internacional: 1,
    });
  }

  updateClaveFiscal(clave: string) {
    return this.http.put<ResponseI<IUserData>>(`${this.API_URL_PE}/clavefiscalinternacional/update`, {
      clave,
      tipo_clave_internacional: 1,
    });
  }

  updateFiscalData(dto: any) {
    return this.http.put<ResponseI<IUserData>>(`${this.API_URL_PE}/usuarioTS/update-fiscal-data`, dto);
  }
}