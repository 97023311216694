import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { API_URL } from 'src/environments/environment';
import { ResponseI } from '../interfaces/response.interface';
import { TaxInfoI } from '../interfaces/tax.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiClaveFiscalService {
  constructor(private http: HttpClient) {}

  create(key: string, jurisdictionCode: string) {
    const body = {
      clave: key,
      jurisdiccion_codigo: jurisdictionCode,
    };

    return this.http.put<ResponseI<TaxInfoI>>(`${API_URL}/clavefiscal/create`, body).pipe(map((res) => res?.data));
  }

  createNew(key: string, jurisdictionCode: string) {
    const body = {
      clave: key,
      jurisdiccion_codigo: jurisdictionCode,
    };

    return this.http.put<ResponseI<TaxInfoI>>(`${API_URL}/clavefiscal/new`, body).pipe(map((res) => res?.data));
  }

  update(key: string, jurisdictionCode: string) {
    const body = {
      clave: key,
      jurisdiccion_codigo: jurisdictionCode,
    };

    return this.http.put<ResponseI<any>>(`${API_URL}/clavefiscal/new-update`, body).pipe(map((res) => res?.data));
  }
}
