import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-get-tax-info',
  templateUrl: './get-tax-info.component.html',
  styleUrls: ['./get-tax-info.component.scss'],
})
export class GetTaxInfoComponent {

  constructor(private modalController: ModalController) {}
  mobile: boolean;

  ngOnInit() {
    this.mobile = this.isMobile();
  }

  async dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  openCompletion() {
    window.open('https://serviciosweb.afip.gob.ar/genericos/formularios/pdf/interactivos/f460f.pdf', '_blank');
  }

  openTurnos() {
    window.open('https://serviciosweb.afip.gob.ar/Publico/Turnos/Turnos.aspx', '_blank');
  }

  goToTutorials() {
    window.open('https://www.youtube.com/watch?v=xpcrXja4tUo&list=PL1f35stVvzDEAuNmuv3nLQ5fjxSLm1zcS', '_blank');
  }

  private isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }


}
