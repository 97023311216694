import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller';
import { CurrentCountryService } from './current-country.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private navController: NavController, private currentCountryService: CurrentCountryService) {}

  /**
   * Prepara la ruta para navegar.
   * Si la ruta no tiene pais (siempre), agregarle el actual.
   */
  private prepareRoute(page: string, options?: NavigationOptions): string[] {
    if (options?.relativeTo) {
      return page.split('/');
    }

    if (!this.currentCountryService.getPathCountry(page)) {
      const currentCountry = this.currentCountryService.get();

      if (page === '/' || page === '') {
        return ['/'];
      }

      page = page.trim().replace(/^\//, '');
      page = `${currentCountry.id}/${page}`;
    }

    return page.split('/');
  }

  navigateRoot(page: string, options?: NavigationOptions) {
    return this.navController.navigateRoot(this.prepareRoute(page, options), options);
  }

  navigateForward(page: string, options?: NavigationOptions) {
    return this.navController.navigateForward(this.prepareRoute(page, options), options);
  }

  navigateBack(page: string, options?: NavigationOptions) {
    return this.navController.navigateBack(this.prepareRoute(page, options), options);
  }

  back(options?: NavigationOptions) {
    this.navController.back(options);
  }
}
