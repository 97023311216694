import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { ResponseI } from 'src/app/interfaces/response.interface';
import { API_URL } from 'src/environments/environment';
import { IProfilePhoto, IUserData, IUserImages } from './user-data.interface';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  private userData: BehaviorSubject<IUserData> = new BehaviorSubject({} as IUserData);
  private userProfilePhoto: BehaviorSubject<string> = new BehaviorSubject('/assets/foto-usuario.png');
  private userImages: BehaviorSubject<IUserImages> = new BehaviorSubject({} as IUserImages);

  constructor(private httpClient: HttpClient) {
    this.userData.subscribe((ud) => console.log('updateUserData: ', ud));
  }

  logout() {
    this.userData.next({} as IUserData);
    this.userProfilePhoto.next('/assets/foto-usuario.png');
  }

  getUserData() {
    return this.userData;
  }

  getUserDataNow() {
    return this.userData.pipe(take(1)).toPromise();
  }

  setUserData(userData: IUserData) {
    this.userData.next(userData);
  }

  esMonotributista(data) {
    return this.httpClient
      .put<ResponseI<any>>(`${API_URL}/usuarioTS/es-monotributista`, data)
      .pipe(map((res) => res.data));
  }

  setUserImages(userImages: IUserImages) {
    this.userImages.next(userImages);
  }

  getUserProfilePhoto() {
    return this.userProfilePhoto;
  }

  setUserProfilePhoto(userProfilePhoto: string) {
    return this.userProfilePhoto.next(userProfilePhoto);
  }

  refreshUserInfo() {
    return this.httpClient
      .get<ResponseI<IUserData>>(`${API_URL}/usuarioTS/info`)
      .pipe(
        map((res) => res?.data),
        filter((userData) => userData != null),
        tap((userData) => this.setUserData(userData))
      )
      .toPromise();
  }

  async getUserImages() {
    const userImages = await this.httpClient
      .get<ResponseI<IUserImages>>(`${API_URL}/usuarioTS/images`)
      .pipe(map((res) => res?.data))
      .toPromise();

    if (userImages) {
      this.setUserImages(userImages);
    }

    return userImages;
  }

  async refreshUserProfilePhoto() {
    const userProfilePhoto = await this.httpClient
      .get<ResponseI<IProfilePhoto>>(`${API_URL}/usuarioTS/fotoUsuario/readFile`)
      .pipe(map((res) => res?.data || null))
      .toPromise();

    if (userProfilePhoto?.file) {
      this.userProfilePhoto.next(userProfilePhoto.file);
    } else {
      this.userProfilePhoto.next('/assets/foto-usuario.png');
    }

    return userProfilePhoto;
  }
}
