import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-slide-pager',
  templateUrl: './slide-pager.component.html',
  styleUrls: ['./slide-pager.component.scss'],
})
export class SlidePagerComponent implements OnInit {
  @Input() currentStep: number;
  @Input() steps: number;

  stepsArray: number[];

  ngOnInit() {
    this.stepsArray = Array.from({ length: this.steps }).map((_, index) => index + 1);
  }
}
