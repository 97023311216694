// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.without-card-style {
  box-shadow: none;
}

.linkPlanSelect {
  padding-top: 6px;
  padding-bottom: 10px;
  text-align: center;
}

.fondo-azul {
  background: blue;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBsYW4tY2FyZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGdCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxnQkFBQTtFQUNBLG9CQUFBO0VBQ0Esa0JBQUE7QUFDRjs7QUFFQTtFQUNFLGdCQUFBO0FBQ0YiLCJmaWxlIjoicGxhbi1jYXJkLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLndpdGhvdXQtY2FyZC1zdHlsZSB7XG4gIGJveC1zaGFkb3c6IG5vbmU7XG59XG5cbi5saW5rUGxhblNlbGVjdCB7XG4gIHBhZGRpbmctdG9wOiA2cHg7XG4gIHBhZGRpbmctYm90dG9tOiAxMHB4O1xuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG59XG5cbi5mb25kby1henVsIHtcbiAgYmFja2dyb3VuZDogYmx1ZTtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/shared/plan-card/plan-card.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,oBAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF;AACA,wkBAAwkB","sourcesContent":[".without-card-style {\n  box-shadow: none;\n}\n\n.linkPlanSelect {\n  padding-top: 6px;\n  padding-bottom: 10px;\n  text-align: center;\n}\n\n.fondo-azul {\n  background: blue;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
