import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class InitAppService {

  constructor(private authS: AuthService) { }

  async load() {
    const token = await this.authS.loadToken();
    console.log('InitAppService - LOAD_TOKEN: ', token);
  }

}
