import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URL } from 'src/environments/environment';
import { ResponseI } from '../interfaces/response.interface';

interface IMarketUrl {
  android: string;
  ios: string;
}
interface IMarketVersion {
  android: string;
  ios: string;
}
interface IVersionResponse {
  versions: IMarketVersion;
  urls: IMarketUrl;
}
@Injectable({
  providedIn: 'root',
})
export class UpdateAppService {
  constructor(private http: HttpClient) {}

  checkForUpdate(): Observable<IVersionResponse> {
    return this.http.get<ResponseI<IVersionResponse>>(`${API_URL}/configuracion/ultima-version`).pipe(map((res) => res?.data));
  }
}
