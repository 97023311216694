// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  text-align: center !important;
  font-size: 1.3rem;
  padding-top: 30px;
  padding-left: 24px;
  padding-right: 24px;
}

ion-button {
  margin: 30px auto 30px;
  width: 14.3rem;
  height: 35px;
  font-size: 1.2rem;
  display: block;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJlc3BvbnNlLW1vZGFsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsNkJBQUE7RUFDQSxpQkFBQTtFQUNBLGlCQUFBO0VBQ0Esa0JBQUE7RUFDQSxtQkFBQTtBQUNGOztBQUNBO0VBQ0Usc0JBQUE7RUFDQSxjQUFBO0VBQ0EsWUFBQTtFQUNBLGlCQUFBO0VBQ0EsY0FBQTtBQUVGIiwiZmlsZSI6InJlc3BvbnNlLW1vZGFsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsicCB7XG4gIHRleHQtYWxpZ246IGNlbnRlciAhaW1wb3J0YW50O1xuICBmb250LXNpemU6IDEuM3JlbTtcbiAgcGFkZGluZy10b3A6IDMwcHg7XG4gIHBhZGRpbmctbGVmdDogMjRweDtcbiAgcGFkZGluZy1yaWdodDogMjRweDtcbn1cbmlvbi1idXR0b24ge1xuICBtYXJnaW46IDMwcHggYXV0byAzMHB4O1xuICB3aWR0aDogMTQuM3JlbTtcbiAgaGVpZ2h0OiAzNXB4O1xuICBmb250LXNpemU6IDEuMnJlbTtcbiAgZGlzcGxheTogYmxvY2s7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/shared/response-modal/response-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AACA;EACE,sBAAA;EACA,cAAA;EACA,YAAA;EACA,iBAAA;EACA,cAAA;AAEF;AACA,gvBAAgvB","sourcesContent":["p {\n  text-align: center !important;\n  font-size: 1.3rem;\n  padding-top: 30px;\n  padding-left: 24px;\n  padding-right: 24px;\n}\nion-button {\n  margin: 30px auto 30px;\n  width: 14.3rem;\n  height: 35px;\n  font-size: 1.2rem;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
