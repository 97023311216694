import { IDatosPeru } from './datos-peru.interface';

export interface IUserData {
  activo: boolean; //
  afip_usuario_t_s: IAfipData;
  apellido: string; //
  celular: string; //
  claves_fiscales: IUDFiscalKey[];
  cuit: string; //
  configuracion: IUDConfiguration; //
  datos_bancarios: IUDBankData; //
  dni: string;
  domicilio_real: any;
  domicilio_fiscal: any;
  email: string; //
  estado: UDEstateENUM; //
  estado_name: string; //
  fecha_de_nacimiento: string; //
  id: number; //
  ingresos_brutos: any;
  jurisdicciones: IUDJurisdiction[];
  jurisdiccion_tributaria: IUDJurisdictionFiscal;
  monotributo: any;
  nombre: string; //
  plan_contratado_vigente: IPlanContratado; //
  tiene_clave_afip: boolean;
  a_que_te_vas_a_dedicar: string;
  s_es_monotributista?: string;
  s_inscripto_i_i_b_b?: string;
  actividad_principal?: any;
  fecha_inicio_actividades?: any;
  ultimas_tres_cuotas?: ICuotas[]; //
  is_servicio_contable?: boolean;
  adherido_debito_automatico: boolean; //
  mostrar_boton_adherirse_debito_automatico: boolean; //
  trabajos_pendientes: Trabajos[]; //
  servicio_contable: boolean;
  servicio_informe_fiscal: boolean;
  servicio_facturacion: boolean; //
  plan_seleccionado: IPlanSeleccionado; //
  tiene_integrado_tienda_nube: boolean;
  tiene_integrado_mercadolibre: boolean;
  pais: IPais;
  usuario_t_s_datos_peru?: IDatosPeru;
}
// Se comparte

export type Trabajos =
  | 'Servicio Informe Fiscal'
  | 'Servicio Facturacion'
  | 'Servicio Facturacion Internacional Peru'
  | 'Servicio Facturacion Internacional Uruguay'
  | 'Servicio Contable'
  | 'Servicio Contable Internacional Peru'
  | 'Servicio Contable Internacional Uruguay'
  | 'Inscribir RUS RER'
  | 'Baja Debito Automatico'
  | 'Servicio Personalizado';

export interface ICuotas {
  is_pagado: boolean;
  id: number;
  beneficiado: boolean;
  monto: number;
  monto_dolar: number;
  year: number;
  month: string;
  fecha_vencimiento: Date;
  fecha_pago: Date;
}

export interface IUserImages {
  fotoUsuario: IUserImage;
  fotoDniFrente: IUserImage; // idCardFront
  fotoDniDorso: IUserImage; // idCardBack
}

export interface IUserImage {
  extension: string;
  file: string;
  mimeType: string;
}

export interface IUDFiscalKey {
  jurisdiccion: IUDJurisdictionFiscal;
}

export interface IUDJurisdictionFiscal {
  id: number;
  codigo: string;
  nombre: string;
  url: string;
}

export interface IUDJurisdiction {
  id: number;
  codigo: string;
  nombre: string;
  nombre_corto: string;
}

// TODO: Verificar cambio de CBU a clave bancaria
export interface IUDBankData {
  cbu: string;
  entidad_bancaria: IUDBankEntity;
  numero: string;
  principal: boolean;
  tipo_cuenta: string;
  tipocuenta_name: string;
  alias: string;
}

export interface IUDBankEntity {
  id: number;
  codigo_cbu: string;
  nombre_legal: string;
  nombre_mostrar: string;
  red_pertenece: number;
}

export interface IUDConfiguration {
  copia_de_mail: boolean;
  enviar_cbu: boolean;
  notificacion_compras_impagas: number;
  notificacion_facturas_impagas: number;
}

export enum UDEstateENUM {
  ESTADO_REGISTRADO = 1, // Registrado
  ESTADO_EMAIL_VALIDADO = 2, // Email Validado
  ESTADO_REINGRESAR_DATOS = 3, // Reingresar Datos
  ESTADO_PLAN_SELECCIONADO = 4, // Plan seleccionado
  ESTADO_CONTRATADO = 5, // Contratado
  ESTADO_SERVICIO_CONTABLE = 6, // Servicio contable
  ESTADO_REVISION = 7, // Revisión
  ESTADO_REVISION_CLAVE_FISCAL = 8, // Revisión de Clave Fiscal
  ESTADO_INFORME = 9, // Informe
  ESTADO_ACTIVO = 10, // Activo
  ESTADO_BLOQUEADO = 11, // Bloqueado
  ESTADO_BAJA = 12, // Baja
}

export interface IProfilePhoto {
  extension: string;
  file: string; // base64
  mimeType: string;
}

export interface IPlanContratado {
  id: number;
  plan: {
    id: number;
    valor_subscripcion: number;
    code: string;
    descripcion: string;
    valor: number;
    valor_dolar?: number;
  };
  fecha_contratacion: string;
  monto: number;
  abono_anual: boolean;
  monto_subscripcion?: number;
  vigente: boolean;
  plan_select: boolean;
}

export interface IPlanSeleccionado {
  id: number;
  plan: {
    id: number;
    code: string;
    descripcion: string;
    valor: number;
    valor_dolar?: number;
    valor_subscripcion?: number;
  };
}

export interface IAfipData {
  id: number;
  resumen: string;
  fecha_calculado: string;
  periodo_calculado: string;
  total_saldo_deudor: number;
  total_saldo_acreedor: number;
  saldo_deudor_obligacion_mensual: number;
  saldo_acreedor_obligacion_mensual: number;
  saldo_deudor_accesorios: number;
  saldo_acreedor_accesorios: number;
  activity: string;
  created_at: string;
  updated_at: string;
  address: string;
  category: string;
  constancia_c_u_i_t: string;
}

export interface IPais {
  id: number;
  nombre: string;
  code: string;
}
