import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CurrentCountryService } from '../services/current-country.service';
import { NavigationService } from '../services/navigation.service';

@Injectable({ providedIn: 'root' })
export class CountryGuard  {
  constructor(private navigationService: NavigationService, private currentCountryService: CurrentCountryService) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    const userCountry = await this.currentCountryService.getUserCountry();

    if (route.data.country != userCountry.id) {
      await this.navigationService.navigateRoot('/home');
    }

    return true;
  }
}
