import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddContactDirective } from './add-contant.directive';
import { AddressDirective } from './address.directive';
import { FiscalKeysDirective } from './fiscal-keys.directive';
import { HomeSlideDirective } from './home-slide.directive';
import { IntegerInputDirective } from './integer-number.directive';
import { KeyboardShowHideDirective } from './keyboard-show-hide.directive';
import { StatisticsFilterDirective } from './statistics-filter.directive';

const declarationsExports = [
  KeyboardShowHideDirective,
  IntegerInputDirective,
  HomeSlideDirective,
  FiscalKeysDirective,
  AddressDirective,
  AddContactDirective,
  StatisticsFilterDirective,
];

@NgModule({
  declarations: [...declarationsExports],
  imports: [CommonModule],
  exports: [...declarationsExports],
})
export class DirectivesModule {}
