import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CurrencyENUM, CurrencySymbolENUM } from '../enums/currency.enum';
import { Country, CountryId } from '../interfaces/country.interface';
import { ApiJurisdiccionService } from './api-jurisdiccion.service';
import { StorageService } from './storage.service';
import { UserDataService } from './user/user-data.service';

export const COUNTRIES: Country[] = [
  {
    id: CountryId.AR,
    name: 'Argentina',
    currency: CurrencyENUM.ARS,
    idLabel: 'DNI',
    idName: 'dni',
    idLength: { min: 8, max: 8 },
    currencySymbol: CurrencySymbolENUM.ARS,
    aboutPage: 'https://tributosimple.com/argentina',
    paymentMethods: [],
    useProveedores: true,
    labels: {
      claveBancaria: 'CBU',
    },
  },
  {
    id: CountryId.PE,
    name: 'Perú',
    currency: CurrencyENUM.PEN,
    idLabel: 'DNI',
    idName: 'dni',
    idLength: { min: 8, max: 8 },
    currencySymbol: CurrencySymbolENUM.PEN,
    aboutPage: 'https://tributosimple.com/peru',
    paymentMethods: [],
    useProveedores: false,
    labels: {
      claveBancaria: 'CCI - Código interbancario',
    },
  },
  {
    id: CountryId.UY,
    name: 'Uruguay',
    currency: CurrencyENUM.UYU,
    idLabel: 'CI',
    idName: 'ci',
    idLength: { min: 8, max: 8 },
    currencySymbol: CurrencySymbolENUM.UYU,
    aboutPage: 'https://tributosimple.com/uruguay',
    paymentMethods: [],
    useProveedores: false,
    labels: {
      claveBancaria: 'CBU',
    },
  },
];

const DEFAULT_COUNTRY = COUNTRIES[0];

@Injectable({ providedIn: 'root' })
export class CurrentCountryService {
  private countrySubject = new BehaviorSubject<Country>(DEFAULT_COUNTRY);
  private api: string = environment.apiUrl[DEFAULT_COUNTRY.id];
  private base: string = environment.baseUrl[DEFAULT_COUNTRY.id];
  private _extraData: any;
  private _countries: Country[];

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private userDataService: UserDataService,
    private injector: Injector
  ) {
    this.init();
  }

  async init() {
    await this.getFromStorage().then((country) => {
      if (country) {
        this.set(country);
      }
    });

    this.userDataService
      .getUserData()
      .pipe(filter((userData) => Object.keys(userData).length > 0))
      .subscribe((userData) => {
        const countryId = userData.pais.code as CountryId;
        console.log(`User country is ${userData.pais.nombre}`);

        if (countryId) {
          this.setById(countryId);
        }
      });
  }

  get() {
    return this.countrySubject.getValue();
  }

  async getUserCountry() {
    const userData = await this.userDataService
      .getUserData()
      .pipe(
        filter((userData) => Object.keys(userData).length > 0),
        take(1)
      )
      .toPromise();

    const countryId = userData.pais.code;

    return COUNTRIES.find((country) => country.id === countryId);
  }

  /**
   * Extraer el pais de la ruta dada.
   */
  getPathCountry(path: string): Country | null {
    COUNTRIES.forEach((country) => {
      if (path.startsWith(`${country.id}/`)) {
        return country;
      }
    });

    return null;
  }

  getObservable() {
    return this.countrySubject.asObservable();
  }

  set(country: Country) {
    if (country) {
      this.api = environment.apiUrl[country.id];
      this.base = environment.baseUrl[country.id];
      this.countrySubject.next(country);
      this.storageService.set('country', country.id);
      this.getCountryList();
    }
  }

  getApiUrl() {
    return this.api;
  }

  getBaseUrl() {
    return this.base;
  }

  async getCountryList() {
    if (!this._countries) {
      const enabledCountries: { code: string; enabled: boolean; metodos_pago_code: string[] }[] = await this.httpClient
        .get<any>(`${environment.apiUrl.ar}/pais/list`)
        .pipe(map((res) => res?.data))
        .toPromise();

      enabledCountries.forEach((country) => {
        COUNTRIES.find((c) => c.id === country.code).paymentMethods = country.metodos_pago_code;
      });

      this._countries = enabledCountries
        .filter((country) => country.enabled)
        .map((country) => COUNTRIES.find((c) => c.id === country.code));
    }

    return this._countries;
  }

  async getExtraData() {
    if (!this._extraData) {
      switch (this.countrySubject.getValue().id) {
        case CountryId.PE: {
          const apiJurisdiccionService = this.injector.get(ApiJurisdiccionService);
          this._extraData = { departments: await apiJurisdiccionService.getJurisdictions().toPromise() };
        }
      }
    }

    return this._extraData;
  }

  private setById(value: string) {
    return this.set(COUNTRIES.find((country) => country.id === value));
  }

  private async getFromStorage() {
    const storedCountry = await this.storageService.get('country');
    return COUNTRIES.find((country) => country.id === storedCountry);
  }
}
