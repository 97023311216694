// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-icon.show-hide-pass {
  z-index: 999;
  font-size: 20px;
  position: absolute;
  right: 0;
  bottom: 8px;
}

.padding {
  padding: 0px 100px;
}
@media only screen and (max-width: 600px) {
  .padding {
    padding: 0px 0px;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNldC10YXgta2V5LWZvcm0uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxZQUFBO0VBQ0EsZUFBQTtFQUNBLGtCQUFBO0VBQ0EsUUFBQTtFQUNBLFdBQUE7QUFDRjs7QUFFQTtFQUNFLGtCQUFBO0FBQ0Y7QUFBRTtFQUZGO0lBR0ksZ0JBQUE7RUFHRjtBQUNGIiwiZmlsZSI6InNldC10YXgta2V5LWZvcm0uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJpb24taWNvbi5zaG93LWhpZGUtcGFzcyB7XG4gIHotaW5kZXg6IDk5OTtcbiAgZm9udC1zaXplOiAyMHB4O1xuICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gIHJpZ2h0OiAwO1xuICBib3R0b206IDhweDtcbn1cblxuLnBhZGRpbmcge1xuICBwYWRkaW5nOiAwcHggMTAwcHg7XG4gIEBtZWRpYSBvbmx5IHNjcmVlbiBhbmQgKG1heC13aWR0aDogNjAwcHgpIHtcbiAgICBwYWRkaW5nOiAwcHggMHB4O1xuICB9XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/components/shared/set-tax-key-form/set-tax-key-form.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;EACA,kBAAA;EACA,QAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;AACF;AAAE;EAFF;IAGI,gBAAA;EAGF;AACF;AACA,osBAAosB","sourcesContent":["ion-icon.show-hide-pass {\n  z-index: 999;\n  font-size: 20px;\n  position: absolute;\n  right: 0;\n  bottom: 8px;\n}\n\n.padding {\n  padding: 0px 100px;\n  @media only screen and (max-width: 600px) {\n    padding: 0px 0px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
