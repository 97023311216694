import { Component, Input } from '@angular/core';
import { HomeSlide } from 'src/app/interfaces/home-slide.interface';
import { IUserData } from 'src/app/services/user/user-data.interface';

@Component({
  selector: 'app-slides-uy',
  templateUrl: './slides.component.html',
  styleUrls: ['./slides.component.scss'],
})
export class SlidesUyComponent implements HomeSlide {
  @Input() userData: IUserData;
  @Input() dateNow: Date;

  slideOpts = {
    initialSlide: 0,
    speed: 400,
  };
}
