// INIT - Condicion IVA
export enum ConditionIVAENUM {
  IVA_RESPONSABLE_INSCRIPTO = 1,
  IVA_SUJETO_EXENTO = 4,
  CONSUMIDOR_FINAL = 5,
  RESPONSABLE_MONOTRIBUTO = 6,
  SUJETO_NO_CATEGORIZADO = 7,
  PROVEEDOR_EXTERIOR = 8,
  CLIENTE_EXTERIOR = 9,
  IVA_LIBERADO = 10,
  IVA_RESPONSABLE_INSCRIPTO_AGENTE_PERCEPCION = 11,
  MONOTRIBUTISTA_SOCIAL = 13,
  IVA_NO_ALCANZADO = 15,
}

export const ContionIVADescENUM = {
  1: 'IVA Responsable Inscripto',
  4: 'IVA Sujeto Exento',
  5: 'Consumidor Final',
  6: 'Responsable Monotributo',
  7: 'Sujeto No Categorizado',
  8: 'Proveedor del Exterior',
  9: 'Cliente del Exterior',
  10: 'IVA Liberado - Ley Nº 19.640',
  11: 'IVA Responsable Inscripto - Agente de Percepción',
  13: 'Monotributista Social',
  15: 'IVA No Alcanzado',
};
// END - Condicion IVA

export enum ReasonENUM {
  CAMBIO_PLAN = 1,
  SOLICITAR_OTROS_SERVICIOS = 2,
  SOLICITAR_BAJA = 3,
  REPORTAR_PROBLEMA = 4,
  CALENDAR = 5
}
