import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DatesService {
  getMonths(): { name: string; value: string }[] {
    return [
      {
        name: 'Enero',
        value: '01',
      },
      {
        name: 'Febrero',
        value: '02',
      },
      {
        name: 'Marzo',
        value: '03',
      },
      {
        name: 'Abril',
        value: '04',
      },
      {
        name: 'Mayo',
        value: '05',
      },
      {
        name: 'Junio',
        value: '06',
      },
      {
        name: 'Julio',
        value: '07',
      },
      {
        name: 'Agosto',
        value: '08',
      },
      {
        name: 'Septiembre',
        value: '09',
      },
      {
        name: 'Octubre',
        value: '10',
      },
      {
        name: 'Noviembre',
        value: '11',
      },
      {
        name: 'Diciembre',
        value: '12',
      },
    ];
  }
}
