import { Component, ComponentFactoryResolver, OnInit, Type, ViewChild } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import * as moment from 'moment';
import { PendingReviewComponent } from 'src/app/components/modals/pending-review/pending-review.component';
import { HomeSlideDirective } from 'src/app/directives/home-slide.directive';
import { ReasonENUM } from 'src/app/interfaces/common.interfaces';
import { HomeSlide } from 'src/app/interfaces/home-slide.interface';
import { IReqConsultaNew } from 'src/app/interfaces/requests/requests.interfaces';
import { ApiConsultaService } from 'src/app/services/api-consulta.service';
import { AppLogService } from 'src/app/services/appLog.service';
import { CurrentCountryService } from 'src/app/services/current-country.service';
import { LoadingService } from 'src/app/services/misc/loading.service';
import { ToastService } from 'src/app/services/misc/toast.service';
import { PushNotificationService } from 'src/app/services/native/push-notification.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { TabItem, TabsService } from 'src/app/services/tabs.prox.service';
import { IUserData, UDEstateENUM } from 'src/app/services/user/user-data.interface';
import { UserDataService } from 'src/app/services/user/user-data.service';
import { ApiNotificationsService } from '../../services/api-notifications.service';

declare let ZohoSalesIQ: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class ProxPage implements OnInit {
  @ViewChild(HomeSlideDirective) homeSlideHost: HomeSlideDirective;

  udeStates = UDEstateENUM;
  userData: IUserData;
  unreadNotifications: number;

  tabs: TabItem[] = [];

  constructor(
    private toastController: ToastController,
    private toastService: ToastService,
    private userDataService: UserDataService,
    private apiConsultaService: ApiConsultaService,
    private currentCountryService: CurrentCountryService,
    private navigationService: NavigationService,
    private apiNotificationsService: ApiNotificationsService,
    private appLoggerService: AppLogService,
    private loadingService: LoadingService,
    private pushNotificationService: PushNotificationService,
    private resolver: ComponentFactoryResolver,
    private tabsService: TabsService,
    private modalController: ModalController
  ) {
    moment.locale('es');
    this.pushNotificationService.initService();
  }

  ngOnInit() {
    const eventValue = { page: 'prox/home' };
    this.appLoggerService.logPageView(eventValue);
  }

  ionViewWillEnter() {
    this.init();
  }

  async init() {
    (function () {
      const js = document.createElement('script');
      js.type = 'text/javascript';
      js.async = true;
      js.src = 'https://go.botmaker.com/rest/webchat/p/JOLAS6SZMT/init.js';
      document.body.appendChild(js);
    })();

    // Unread Notifications
    this.apiNotificationsService.getNotifications(0, 0).subscribe((res) => {
      if (res) {
        this.unreadNotifications = res?.no_leidos;
        if (this.unreadNotifications > 999) this.unreadNotifications = 999;
      }
    });

    // UserData
    this.userData = await this.userDataService.refreshUserInfo();
    console.log('userData:', this.userData);

    this.loadingService.dismiss();

    if (this.userData) {
      this.verificarEstado();

      let component: Type<HomeSlide>;

      if (component) {
        this.homeSlideHost.viewContainerRef.clear();
        const contentFactory = this.resolver.resolveComponentFactory(component);
        const contentRef = this.homeSlideHost.viewContainerRef.createComponent(contentFactory);
        contentRef.instance.userData = this.userData;
        contentRef.instance.dateNow = new Date();
      }

      this.tabs = await this.tabsService.buildTabs();
    }
  }

  async verificarEstado() {
    const { estado, id } = this.userData;

    switch (estado) {
      case UDEstateENUM.ESTADO_CONTRATADO:
        if (this.userData.tiene_clave_afip) {
          const body: IReqConsultaNew = {
            motivo: ReasonENUM.CAMBIO_PLAN,
          };

          this.apiConsultaService.putQuery(body).subscribe(
            async () => {
              await this.userDataService.refreshUserInfo(); // Actualizar datos del usuario
            },
            (err) => {
              console.error(err);
            }
          );
        } else {
          this.navigationService.navigateForward('/tax-keys/tax-form');
        }
        return;
      case UDEstateENUM.ESTADO_REGISTRADO:
        this.navigationService.navigateRoot('/check-account');
        return;
      case UDEstateENUM.ESTADO_EMAIL_VALIDADO:
        this.navigationService.navigateRoot('/plans/choice-type');
        return;
      case UDEstateENUM.ESTADO_PLAN_SELECCIONADO:
        const navigationExtras: NavigationExtras = { state: { usuarioTSID: id } };
        if (this.userData.domicilio_fiscal) {
          this.navigationService.navigateRoot('/plans/detail', { state: { canBuy: true } });
        } else {
          this.navigationService.navigateRoot('/plans/choice-type/step0', navigationExtras);
        }
        return;
      case UDEstateENUM.ESTADO_REVISION:
      case UDEstateENUM.ESTADO_SERVICIO_CONTABLE:
      case UDEstateENUM.ESTADO_REVISION_CLAVE_FISCAL:
        const modal = await this.modalController.create({
          component: PendingReviewComponent,
          backdropDismiss: false,
          cssClass: 'modal-bienvenido',
        });
        modal.present();
        return;
    }
  }

  openNotifications() {
    this.navigationService.navigateForward('/notifications');
  }

  showChat() {
    if (this.userData.pais.code === 'pe') {
      const url = 'https://tributosimple.com/peru/contacto-wpp-web';
      window.open(url, '_system');
    }
    if (this.userData.pais.code === 'uy') {
      const url = 'https://tributosimple.com/uruguay/contacto-wpp-web';
      window.open(url, '_system');
    }
  }

  private isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  async presentToast(message: string) {
    const js = document.createElement('script');
    js.type = 'text/javascript';
    js.async = true;
    js.src = 'https://go.botmaker.com/rest/webchat/p/C3LQKHS1ZZ/init.js';
    document.body.appendChild(js);
    const toast = await this.toastController.create({
      message: message,
      duration: 2000, // Duración del Toast en milisegundos
      position: 'bottom', // Puedes ajustar la posición según tus preferencias
    });
    toast.present();
  }

  dismissToast() {
    this.toastController.dismiss();
  }

  openChat2() {
    this.presentToast('Abriendo chat');
  }
}
