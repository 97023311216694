import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from './components/shared/shared.module';
import { DirectivesModule } from './directives/directives.module';
import { MenuComponent } from './menu.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IonicModule, SharedModule, DirectivesModule],
  declarations: [MenuComponent],
  exports: [MenuComponent]
})
export class MenuModule {}