import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UsuarioTSService } from 'src/app/countries/ar/services/usuario-ts.service';
import { getErrorMsg } from 'src/app/helpers/utils';
import { ApiClaveFiscalService } from 'src/app/services/api-clave-fiscal.service';
import { LoadingService } from 'src/app/services/misc/loading.service';
import { ToastService } from 'src/app/services/misc/toast.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { TaxKeyState } from 'src/app/services/states/tax-key.state';
import { IUDJurisdiction, UDEstateENUM } from 'src/app/services/user/user-data.interface';
import { UserDataService } from 'src/app/services/user/user-data.service';
import { GetTaxInfoComponent } from '../get-tax-info/get-tax-info.component';

@Component({
  selector: 'app-set-tax-key-form',
  templateUrl: './set-tax-key-form.component.html',
  styleUrls: ['./set-tax-key-form.component.scss'],
})
export class SetTaxKeyFormComponent {
  @Input() code: string;
  @Input() jurisdiction: IUDJurisdiction;
  @Input() cuit: string;
  @Input() isNewCode = false;
  @Input() fromTaxFormRefreshUser = false;
  @Input() fromProfile = false;

  private isShowPass = false;
  taxKey: string;
  regexN = /[0-9]/g; // Expresión regular que encuentra todos los números en una cadena
  regexS = /[a-zA-Z]/g; // Expresión regular que encuentra todas las letras en una cadena
  regexCL = /[A-Z]/g; // Expresión regular que encuentra todas las letras mayúsculas en una cadena
  regexLC = /[a-z]/g; // Expresión regular que encuentra todas las letras minúsculas en una cadena
  constructor(
    private taxKeyState: TaxKeyState,
    private apiClaveFiscalService: ApiClaveFiscalService,
    private userDataService: UserDataService,
    private navigationService: NavigationService,
    private usuarioTSService: UsuarioTSService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private modalController: ModalController
  ) {}

  async create() {
    if(this.jurisdiction.codigo == '001'){
      if(this.taxKey.length < 10){
        this.toastService.present({
          message: 'La clave debe tener al menos una longitud mínima de diez caracteres',
          type: 'danger',
        });
        return
      }
      const countN = (this.taxKey.match(this.regexN) || []).length; // Cuenta la cantidad de números en la cadena
      if(countN < 2){
        this.toastService.present({
          message: 'La clave debe tener al menos dos caracteres numéricos',
          type: 'danger',
        });
        return
      }
      const countS = (this.taxKey.match(this.regexS) || []).length; // Cuenta la cantidad de letras en la cadena
      if(countS < 4){
        this.toastService.present({
          message: 'La clave debe tener al menos cuatro letras',
          type: 'danger',
        });
        return
      }
      const hasUppercase = this.regexCL.test(this.taxKey); // Devuelve true si se encuentra al menos una letra mayúscula en la cadena
      if (!hasUppercase) {
        this.toastService.present({
          message: 'La clave debe tener al menos una mayúscula',
          type: 'danger',
        });
        return
      }
      const hasLowercase = this.regexLC.test(this.taxKey); // Devuelve true si se encuentra al menos una letra minúscula en la cadena
      if (!hasLowercase) {
        this.toastService.present({
          message: 'La clave debe tener al menos una minúscula',
          type: 'danger',
        });
        return
      }
    }
    await this.loadingService.present();
    if (this.isNewCode) {
      const code = this.jurisdiction ? this.jurisdiction.codigo : this.code;
      this.apiClaveFiscalService.createNew(this.taxKey, code).subscribe(
        async ({ claves_fiscales }) => {
          console.log(claves_fiscales);

          this.loadingService.dismiss();

          if (this.fromProfile) {
            this.toastService.present({
              message: 'Clave guardada con éxito',
            });
            this.dismiss(true);
          } else {
            this.taxKeyState.setCreatedKeys(claves_fiscales.map((el) => el.jurisdiccion.codigo));
            this.toastService.present({
              message: 'Clave guardada con éxito',
            });

            this.dismiss();

            if (this.fromTaxFormRefreshUser) {
              const ud = await this.userDataService.refreshUserInfo();
              if (ud.estado === UDEstateENUM.ESTADO_SERVICIO_CONTABLE || ud.estado === UDEstateENUM.ESTADO_INFORME) {
                this.navigationService.navigateRoot('/tax-keys/tax-form/success', { state: { udEstado: ud.estado } });
              } else {
                this.navigationService.navigateRoot('/tax-keys/tax-form/success');
              }
            } else {
              this.navigationService.navigateRoot('/tax-keys/tax-form/success');
            }
          }
        },
        (err) => {
          this.loadingService.dismiss();
          console.error(err);
          this.toastService.present({
            message: getErrorMsg(err),
            type: 'danger',
          });
          this.dismiss();
        }
      );
    } else {
      if (this.fromProfile) {
        const code = this.jurisdiction ? this.jurisdiction.codigo : this.code;
        this.apiClaveFiscalService.update(this.taxKey, code).subscribe(
          () => {
            this.loadingService.dismiss();
            this.toastService.present({
              message: 'Clave guardada con éxito',
            });
            this.dismiss(true);

          },
          (err) => {
            this.loadingService.dismiss();
            console.error(err);
            this.toastService.present({
              message: getErrorMsg(err),
              type: 'danger',
            });
            this.dismiss();
          }
        );
      }
    }
  }

  async save() {
    if (!this.cuit) {
      return this.toastService.present({
        message: 'Debes introducir tu CUIT primero.',
        type: 'danger',
      });
    }

    if (this.cuit.length !== 11) {
      return this.toastService.present({
        message: 'El CUIT debe ser válido.',
        type: 'danger',
      });
    }
    await this.loadingService.present();
    this.usuarioTSService.updateCUIT(this.cuit).subscribe(
      async () => {
        this.loadingService.dismiss();
        this.navigationService.navigateRoot('/profile/finish');
      },
      (err) => {
        console.error(err);
        this.loadingService.dismiss();
        this.toastService.present({
          message: 'Cuit inválido',
          type: 'danger',
        });
      }
    );

  }

  async dismiss(refresh?: boolean) {
    this.modalController.dismiss({
      refresh,
      dismissed: true,
    });
    this.save()
  }

  async openInfo() {
    const modal = await this.modalController.create({
      component: GetTaxInfoComponent,
    });

    return await modal.present();
  }

  hasShowPass() {
    return this.isShowPass;
  }

  showPass() {
    this.isShowPass = !this.isShowPass;
  }
}
