import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { UserDataService } from '../services/user/user-data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    private authService: AuthService,
    private userDataService: UserDataService,
    private navController: NavController
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isLoggedIn.pipe(
      switchMap((loggedIn) =>
        loggedIn
          ? from(this.userDataService.refreshUserInfo()).pipe(map(() => true))
          : from(this.navController.navigateRoot('/login')).pipe(map(() => false))
      )
    );
  }
}
