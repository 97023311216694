/**
 * FLAG = 'Angular Route Page'
 */
export enum SectionPageENUM {
  IIBB_LAUNCHER = '',
  IIBB_REGISTER = 'register',
  IIBB_UNSUBSCRIBE = 'unsubscribe',
  IIBB_P_MONTHLY = 'monthly-presentation',
  IIBB_P_ANNUAL = 'annual-presentation',
  PAYMENTS = '',
  PAY_MONOTRIBUTO = 'monotributo',
  PAY_IIBB = 'iibb',
  PAY_TS = '',
}
