import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage | null = null;
  private isReady: Promise<void>;

  constructor(private storage: Storage) {
    this.isReady = this.init();
  }

  async init() {
    try {
      const storage = await this.storage.create();
      this._storage = storage;
    } catch (error) {
      console.error('Error initializing storage:', error);
      throw error;
    }
  }

  async set(key: string, value: any) {
    try {
      await this.isReady;
      if (this._storage) {
        return this._storage.set(key, value);
      } else {
        throw new Error('Storage is not ready');
      }
    } catch (error) {
      console.error('Error setting value in storage:', error);
      throw error;
    }
  }

  async get(key: string) {
    try {
      await this.isReady;
      if (this._storage) {
        return this._storage.get(key);
      } else {
        throw new Error('Storage is not ready');
      }
    } catch (error) {
      console.error('Error getting value from storage:', error);
      throw error;
    }
  }

  async remove(key: string) {
    try {
      await this.isReady;
      if (this._storage) {
        return this._storage.remove(key);
      } else {
        throw new Error('Storage is not ready');
      }
    } catch (error) {
      console.error('Error removing value from storage:', error);
      throw error;
    }
  }
}
