import { Injectable } from '@angular/core';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Capacitor } from '@capacitor/core';

export interface EventValue {
  [x: string]: any;
}
export type EventName =
  | 'page_view'
  | 'ts_first_open'
  | 'ts_registered'
  | 'ts_onboarding_4'
  | 'ts_facturador_onboard'
  | 'ts_facturador_onboard_2'
  | 'ts_facturador_onboard_3'
  | 'ts_facturador_onboard_4'
  | 'ts_informe_onboard'
  | 'ts_informe_onboard_2'
  | 'ts_servicio_onboard'
  | 'ts_servicio_onboard_2'
  | 'ts_servicio_onboard_3'
  | 'ts_servicio_onboard_4'
  | 'ts_servicio_onboard_5'
  | 'ts_facturador_facturando'
  | 'ts_facturador_facturar'
  | 'ts_facturador_facturado'
  | 'ts_alta_onboarding'
  | 'ts_alta_onboarding_2'
  | 'ts_alta_onboarding_3'
  | 'ts_alta_correcta'
  | 'ts_baja_onboard'
  | 'ts_baja'
  | 'ts_recategorizacion';

@Injectable({
  providedIn: 'root',
})
export class AppLogService {
  constructor(private fb: Facebook) {}

  logPageView(value: EventValue): void {
    this.logEvent('page_view', value);
  }

  logEvent(name: EventName, values: EventValue = {}) {
    if (Capacitor.isNativePlatform()) {
      FirebaseAnalytics.logEvent({ name, params: values });
      this.fb.logEvent(name, values);
    }
  }
}
