import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { NavigationService } from '../services/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard  {
  constructor(private navigationService: NavigationService, private authService: AuthService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable<boolean>((obs) => {
      this.authService.getloggedIn().subscribe((loggedIn) => {
        if (!loggedIn) {
          return obs.next(true);
        } else {
          this.navigationService.navigateRoot('/home');
          return obs.next(false);
        }
      });
    });
  }
}
