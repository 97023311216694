import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { MobileAccessibility } from '@awesome-cordova-plugins/mobile-accessibility/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { IonicModule, IonicRouteStrategy, isPlatform } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
// import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import * as localForage from 'localforage';
import { NgChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DirectivesModule } from './directives/directives.module';
import { MenuModule } from './menu.module';
import { InitAppService } from './services/init-app.service';
import { httpInterceptorProviders } from './services/interceptors/main.interceptor';
// import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';

const getConfig = () => {
  const config: any = {
    mode: 'md',
  };

  if (isPlatform('ios')) {
    // FIXME: temporal - probar
    config.swipeBackEnabled = false;
  }

  return config;
};

localForage.config({
  driver: [localForage.LOCALSTORAGE, localForage.INDEXEDDB, localForage.WEBSQL],
  name: '__tributosimple',
  version: 1.0,
  storeName: 'keyvaluepairs', // Should be alphanumeric, with underscores.
  description: 'some description',
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    NgChartsModule,
    IonicModule.forRoot(getConfig()),
    IonicStorageModule.forRoot({
      name: '__tributosimple',
    }),
    HttpClientModule,
    DirectivesModule,
    MenuModule,
    // AndroidPermissions,
    // GoogleTagManagerModule.forRoot({
    //   id: 'AW-16458536000',
    // })
  ],
  providers: [
    InAppPurchase2,
    InAppBrowser,
    FileOpener,
    SocialSharing,
    httpInterceptorProviders,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: InitAppService) => () => ds.load(),
      deps: [InitAppService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'es-AR' },
    MobileAccessibility,
    Facebook,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
