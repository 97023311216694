import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MonthPipe } from 'src/app/pipes/month.pipe';
import { GenericModalComponent } from '../modals/generic-modal/generic-modal.component';
import { BirthdayComponent } from './birthday/birthday.component';
import { ClaveFiscalComponent } from './clave-fiscal/clave-fiscal.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { CountrySelectorComponent } from './country-selector/country-selector.component';
import { FormRealAddressComponent } from './form-real-address/form-real-address.component';
import { GetTaxInfoComponent } from './get-tax-info/get-tax-info.component';
import { HeaderShapedComponent } from './header-shaped/header-shaped.component';
import { PlanCardComponent } from './plan-card/plan-card.component';
import { ResponseModalComponent } from './response-modal/response-modal.component';
import { SetTaxKeyFormComponent } from './set-tax-key-form/set-tax-key-form.component';
import { SlidePagerComponent } from './slide-pager/slide-pager.component';
import { UserProfileAvatarComponent } from './user-profile-avatar/user-profile-avatar.component';

const exportsDeclarations = [
  ContactListComponent,
  GetTaxInfoComponent,
  HeaderShapedComponent,
  PlanCardComponent,
  SetTaxKeyFormComponent,
  UserProfileAvatarComponent,
  FormRealAddressComponent,
  BirthdayComponent,
  GenericModalComponent,
  MonthPipe,
  ClaveFiscalComponent,
  CountrySelectorComponent,
  ResponseModalComponent,
  SlidePagerComponent,
];

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,

  ],
  declarations: [...exportsDeclarations],
  exports: [...exportsDeclarations],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
