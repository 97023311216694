import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDevice } from '../interfaces/device.interfaces';
import { ResponseI } from '../interfaces/response.interface';
import { API_URL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiDeviceService {

  constructor(private http: HttpClient) {}

  register(deviceInfo: IDevice) {
    return this.http.post<ResponseI<any>>(`${API_URL}/device/register`, deviceInfo);
  }
}
