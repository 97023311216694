import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { JurisdictionI } from '../interfaces/plans.interface';
import { ResponseI } from '../interfaces/response.interface';
import { CurrentCountryService } from './current-country.service';

@Injectable({
  providedIn: 'root',
})
export class ApiJurisdiccionService {
  get API_URL() {
    return this.currentCountryApiService.getApiUrl();
  }

  constructor(private http: HttpClient, private currentCountryApiService: CurrentCountryService) {}

  getJurisdictions<T = JurisdictionI>() {
    return this.http
      .get<ResponseI<T[]>>(`${this.API_URL}/jurisdiccion/list`)
      .pipe(map((res) => res?.data || []));
  }
}
