import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

interface ToastServiceOptions { message?: string; duration?: number; type?: string; position?: any; }

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private toast: HTMLIonToastElement;

  constructor(private toastController: ToastController) { }

  async present(
    { message, duration, type, position }: ToastServiceOptions
  ) {
    this.toast = await this.toastController.create({
      message: message || 'No definido.',
      duration: duration || 4000,
      color: type || 'success',
      position: position || 'bottom',
    });
    this.toast.present();
  }

  hide() {
    this.toast.dismiss();
  }

}
