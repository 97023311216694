import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { API_URL } from 'src/environments/environment';
import { IRegId } from '../interfaces/reg-id.interface';
import { ResponseI } from '../interfaces/response.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiUserService {

  constructor(private http: HttpClient) { }

  getResendEmail() {
    return this.http.get<ResponseI<any>>(`${API_URL}/users/validation/email/resend`).pipe(map(res => res?.data || {}));
  }

  postRegId(regId: string) {
    const body = {
      referencia: regId
    };
    return this.http.post<ResponseI<IRegId>>(`${API_URL}/users/regid`, body);
  }

}
