import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpenaiService {

  private apiUrl = 'https://api.openai.com/v1/chat/completions';
  private apiKey = 'sk-48_PTWNfPXKvSxP9eA91tDnfconcBdd561wGiD5-VVT3BlbkFJ9pKi1mht6EY_0EMsMRRD7W_UaT-CEHKwqxh7GBrokA';
  private httpClient: HttpClient;

  constructor(private handler: HttpBackend) {
    // Crear un nuevo HttpClient que no pase por los interceptores
    this.httpClient = new HttpClient(handler);
  }

  sendMessage(message: string): Observable<any> {
    const requestBody = {
      model: 'gpt-4o-mini',
      messages: [{ role: 'user', content: message }],
    };

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.apiKey}`,
      'Content-Type': 'application/json'
    });

    return this.httpClient.post(this.apiUrl, requestBody, { headers });
  }
}