import { CountryProperties } from './country-properties.interfaces';

export enum CountryId {
  AR = 'ar',
  PE = 'pe',
  UY = 'uy',
}

export interface Country extends CountryProperties {
  id: CountryId;
  name: string;
}
