import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Country } from 'src/app/interfaces/country.interface';
import { CurrentCountryService } from 'src/app/services/current-country.service';

@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss'],
})
export class CountrySelectorComponent {
  countries: Country[] = [];
  country$: Observable<Country>;
  public customOptions: any = {
    header: "Seleccione un país",
    cssClass:'mc-wider-popover'
  };

  constructor(private currentCountryService: CurrentCountryService) {
    this.country$ = this.currentCountryService.getObservable();

    this.currentCountryService.getCountryList().then((countries) => (this.countries = countries));
  }

  async changeCountry(event: any) {
    const country = event.detail.value as Country;
    const currentCountry = this.currentCountryService.get();

    if (country === currentCountry) {
      return;
    }

    await this.currentCountryService.set(country);
  }

  setDisplayText(country: Country) {
    return `${country.name}`;
  }
}
