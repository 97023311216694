import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DatesService } from 'src/app/services/dates.service';

@Component({
  selector: 'app-birthday',
  templateUrl: './birthday.component.html',
  styleUrls: ['./birthday.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BirthdayComponent,
    },
  ],
})
export class BirthdayComponent implements OnInit, ControlValueAccessor {
  form: UntypedFormGroup;

  onChange: any;
  onTouched: any;
  disabled = false;

  days: string[];
  months: { name: string; value: string }[];
  years: string[];

  value: any;

  constructor(private fb: UntypedFormBuilder, private dateSrv: DatesService) {}
  ngOnInit() {
    this.days = this.getDays();
    this.months = this.getMonths();
    this.years = this.getYears();
    console.log(this.years);

    this.form = this.fb.group({
      day: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      month: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      year: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    });

    // on form changes
    this.form.valueChanges.subscribe((data) => {
      if (this.form.valid) {
        const day = data.day;
        const month = data.month;
        const year = data.year;

        const date = moment(year + '-' + month + '-' + day, 'YYYY-MM-DD').format('YYYY-MM-DD');
        this.onChange(date);
      }
    });
  }

  writeValue(value: any): void {
    this.value = value;
    if (this.value) {
      const date = this.value.split('-');
      if (date) {
        this.form.patchValue({
          day: date[2],
          month: date[1],
          year: date[0],
        });
      }
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getYears() {
    const max = new Date().getFullYear() - 16;
    const min = max - 90;
    const years = [];

    for (let i = max; i >= min; i--) {
      years.push(i.toString());
    }
    return years;
  }

  getDays(): string[] {
    const days = Array.from(Array(32).keys());
    days.shift();
    const stringDays = days.map((item: any) => {
      item = item.toString();
      if (item.length === 1) {
        item = '0' + item;
      }
      return item;
    });

    return stringDays;
  }

  getMonths(): { name: string; value: string }[] {
    return this.dateSrv.getMonths();
  }
}
