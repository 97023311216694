// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.msg {
  text-align: center !important;
  margin: 60px 2px 20px 2px;
}

.continuar {
  margin: 0 20px 20px 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBlbmRpbmctcmV2aWV3LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsNkJBQUE7RUFDQSx5QkFBQTtBQUNGOztBQUNBO0VBQ0Usd0JBQUE7QUFFRiIsImZpbGUiOiJwZW5kaW5nLXJldmlldy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5tc2cge1xuICB0ZXh0LWFsaWduOiBjZW50ZXIgIWltcG9ydGFudDtcbiAgbWFyZ2luOiA2MHB4IDJweCAyMHB4IDJweDtcbn1cbi5jb250aW51YXIge1xuICBtYXJnaW46IDAgMjBweCAyMHB4IDIwcHg7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/modals/pending-review/pending-review.component.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,yBAAA;AACF;;AACA;EACE,wBAAA;AAEF;AACA,4dAA4d","sourcesContent":[".msg {\n  text-align: center !important;\n  margin: 60px 2px 20px 2px;\n}\n.continuar {\n  margin: 0 20px 20px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
