import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { NavController } from '@ionic/angular';
import * as $ from 'jquery';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ToastService } from 'src/app/services/misc/toast.service';
import { API_URL, BASE_URL } from 'src/environments/environment';
import { IDevice } from '../interfaces/device.interfaces';
import { ApiDeviceService } from './api-device.service';
import { PushNotificationService } from './native/push-notification.service';
import { StorageService } from './storage.service';
import { UserDataService } from './user/user-data.service';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authSubject = new BehaviorSubject<string>('');

  constructor(
    private toastService: ToastService,
    private httpClient: HttpClient,
    private storageService: StorageService,
    private apiDeviceService: ApiDeviceService,
    private pushNotificationService: PushNotificationService,
    private userDataService: UserDataService,
    private navController: NavController
  ) {}

  get isLoggedIn() {
    return this.authSubject.pipe(map((token) => token?.length > 0));
  }

  login(data: any): Observable<any> {
    return this.httpClient.post<any>(`${API_URL}/users/login`, data).pipe(
      switchMap(async ({ data: { token }, refresh_token }) => {
        if (token && refresh_token) {
          await this.storageService.set('ACCESS_TOKEN', token);
          // localStorage.setItem('__tributosimple/_ionickv/ACCESS_TOKEN', token);

          await this.storageService.set('REFRESH_TOKEN', refresh_token);
          // localStorage.setItem('__tributosimple/_ionickv/REFRESH_TOKEN', refresh_token);
          // localStorage.setItem('__tributosimple/_ionickv/country', 'ar');

          this.authSubject.next(token);
          this.registerDevice();
        }
      })
    );
  }

  async registerDevice() {
    const info = await Device.getInfo();
    const id = (await Device.getId()).identifier;
    const infoDevice: IDevice = {
      uuid: id,
      name: info.name,
      model: info.model,
      platform: info.platform,
      operatingSystem: info.operatingSystem,
      osVersion: info.osVersion,
      manufacturer: info.manufacturer,
      isVirtual: info.isVirtual,
    };
    this.apiDeviceService.register(infoDevice).subscribe({
      next: (res) => {
        console.log('registerDevice FOI', res); // Respuesta exitosa
      },
      error: (err) => {
        console.error('Error al registrar el dispositivo', err); // Muestra el error en la consola
        if (err.status === 500) {
          console.error('Error 500: Problema en el servidor'); // Mensaje específico para el error 500

          // Borrar los tokens almacenados
          this.storageService.remove('ACCESS_TOKEN');
          this.storageService.remove('REFRESH_TOKEN');

          // Si es necesario, también puedes limpiar cualquier otro dato relevante
          this.authSubject.next(null); // Limpia el valor actual del token en authSubject
          this.toastService.present({
            message: 'Usted intentando ingresar en la APP con una cuenta de backoffice',
            type: 'danger',
            duration: 5000, // Duración de 5 segundos
            position: 'top', // Posición del toast
          });

        }
      },
      complete: () => {
        console.log('Registro de dispositivo completado'); // Opcional, se ejecuta cuando la operación se completa
      },
    });
  }

  refreshToken() {
    return new Promise<string>(async (resolve) => {
      const body = {
        refresh_token: await this.storageService.get('REFRESH_TOKEN'),
      };
      this.httpClient.post<any>(`${API_URL}/users/refreshToken`, body).subscribe(
        async ({ data: { token }, refresh_token }) => {
          if (token && refresh_token) {
            await this.storageService.set('ACCESS_TOKEN', token);
            console.log('RRR: ', refresh_token);
            await this.storageService.set('REFRESH_TOKEN', refresh_token);
            this.authSubject.next(token);
            resolve(token);
          } else {
            resolve(null);
          }
        },
        (err) => {
          console.log(err);
          resolve(null);
        }
      );
    });
  }

  // Carga el token si el usuario previamente habia iniciado session
  // Importante, se usa para la carga inicial de la app
  async loadToken() {
    const token = await this.storageService.get('ACCESS_TOKEN');
    console.log('ACCESS_TOKEN', token);
    this.authSubject.next(token);
    return token;
  }

  async logout() {
    this.pushNotificationService.closeService();
    await this.storageService.remove('ACCESS_TOKEN');
    await this.storageService.remove('REFRESH_TOKEN');
    this.authSubject.next(null);
    $('ion-loading').remove();
    this.userDataService.logout();
    this.navController.navigateRoot('/login');
  }

  getToken() {
    return this.authSubject.value;
  }

  getloggedIn() {
    return this.authSubject.asObservable();
  }

  recoverPass(data): Observable<any> {
    return this.httpClient.post<any>(`${BASE_URL}​/admin/api/v1/user/recover-password`, data);
  }
}
