import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { IContact } from 'src/app/interfaces/contact.interfaces';
import { ApiContactoUsuarioTSService } from 'src/app/services/api-contacto-usuariots.service';
import { CurrentCountryService } from 'src/app/services/current-country.service';
@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
})
export class ContactListComponent implements OnInit {
  @Output() returnAdd = new EventEmitter<any>();
  @Output() returnEdit = new EventEmitter<IContact>();
  @Input() showModal = false;
  @Input() onlyProveedores = false;
  @Input() onlyClients = false;
  @Input() receiptType?: string;

  contacts: IContact[] = [];
  contactsFilter: IContact[] = [];

  selectedType: number = null;
  searchValue = '';
  show: boolean;
  constructor(
    private router: Router,
    private apiContactoUsuarioTSService: ApiContactoUsuarioTSService,
    private currentCountryService: CurrentCountryService,
    private modalController: ModalController
  ) {}

  get showSelector() {
    return !this.onlyClients && !this.onlyProveedores && this.currentCountryService.get().useProveedores;
  }

  ngOnInit() {
    this.show = false;
    this.checkUrl();
    if (this.onlyProveedores && this.currentCountryService.get().useProveedores) {
      this.selectType(2);
    } else if (this.onlyClients) {
      this.selectType(1);
    } else {
      this.getAll();
    }
  }

  checkUrl(): void {
    this.show = this.router.url.includes('/ar/management/contacts');
  }

  add() {
    if (this.showModal) {
      this.returnAdd.next(true);
    } else {
      this.returnAdd.emit();
    }
  }

  close() {
    if (this.showModal) {
      this.modalController.dismiss();
    }
  }

  selectContact(contact: IContact) {
    if (this.showModal) {
      this.modalController.dismiss(contact, null, 'contact-list-modal');
    } else {
      this.returnEdit.emit(contact);
    }
  }

  filterList(e: any) {
    const value: string = e?.target?.value || '';
    console.log('filterList: ', value);
    this.searchValue = value;
    this.filterContactsHttp();
  }

  selectType(type: number) {
    if (this.selectedType === type) {
      this.selectedType = null;
    } else {
      this.selectedType = type;
    }
    this.filterContactsHttp();
  }

  private getAll(type?: string) {
    this.apiContactoUsuarioTSService.getAll({ tipoCliente: type, tipoFactura: this.receiptType }).subscribe(
      (contacts) => {
        console.log('contacts getAll: ', contacts);
        this.contacts = contacts;
        this.contactsFilter = this.contacts;
      },
      (err) => {
        console.error(err);
        this.contacts = [];
        this.contactsFilter = [];
      }
    );
  }

  private filterContactsHttp() {
    let type = '';

    if (this.selectedType) {
      type = this.selectedType === 1 ? 'cliente' : 'proveedor';
    }

    if (this.searchValue !== '') {
      this.apiContactoUsuarioTSService
        .search(this.searchValue, { tipoCliente: type, tipoFactura: this.receiptType })
        .subscribe(
          (contacts) => {
            console.log('contacts search: ', contacts);
            this.contacts = contacts;
            this.contactsFilter = this.contacts;
          },
          (err) => {
            console.error(err);
            this.contacts = [];
            this.contactsFilter = [];
          }
        );
    } else {
      this.getAll(type);
    }
  }
}
