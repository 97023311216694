import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { filter, take } from 'rxjs/operators';
import { ToastService } from 'src/app/services/misc/toast.service';
import { CurrentCountryService } from './current-country.service';
import { NavigationService } from './navigation.service';
import { IUserData, UDEstateENUM } from './user/user-data.interface';
import { UserDataService } from './user/user-data.service';
import { ZohoService } from './zoho.service';

export type TabItem = {
  id: string;
  label: string;
  weight: number;
  icon?: string;
  img?: string;
  disabled: boolean;
  show: boolean;
  action: () => void;
};

@Injectable({
  providedIn: 'root',
})
export class TabsService {
  constructor(
    private toastController: ToastController,
    private toastService: ToastService,

    private currentCountryService: CurrentCountryService,
    private userDataService: UserDataService,
    private navigationService: NavigationService,
    private zohoService: ZohoService
  ) {}

  private buildFacturar(userData: IUserData): TabItem {
    return {
      id: 'facturar',
      label: 'Facturar',
      weight: -1,
      icon: 'calculator-outline',
      disabled:
        !userData?.activo ||
        userData?.estado === UDEstateENUM.ESTADO_BLOQUEADO ||
        userData?.estado === UDEstateENUM.ESTADO_BAJA ||
        !userData?.servicio_facturacion,
      show: true,
      action: () => this.navigationService.navigateRoot('/servicios/facturacion/facturar'),
    };
  }

  private buildPagos(userData: IUserData): TabItem {
    return {
      id: 'pagos',
      label: 'Pagos',
      weight: 0,
      icon: 'wallet-outline',
      disabled: !userData?.activo || userData?.estado === UDEstateENUM.ESTADO_BAJA || !userData?.is_servicio_contable,
      show: true,
      action: () => this.navigationService.navigateRoot('/payments/estado-tributo-simple'),
    };
  }

  private buildEstadisticas(userData: IUserData): TabItem {
    return {
      id: 'estadisticas',
      label: 'Estadísticas',
      weight: 1,
      icon: 'pie-chart-outline',
      disabled:
        !userData?.activo ||
        userData?.estado === UDEstateENUM.ESTADO_BLOQUEADO ||
        userData?.estado === UDEstateENUM.ESTADO_BAJA ||
        (!userData?.is_servicio_contable && !userData?.servicio_informe_fiscal),
      show: true,
      action: () => {
        this.navigationService.navigateRoot(
          userData.is_servicio_contable ? '/statistics/sales' : '/statistics/last-reports'
        );
      },
    };
  }

  async presentToast(message: string) {
    const js = document.createElement('script');
    js.type = 'text/javascript';
    js.async = true;
    js.src = 'https://go.botmaker.com/rest/webchat/p/C3LQKHS1ZZ/init.js';
    document.body.appendChild(js);
    const toast = await this.toastController.create({
      message: message,
      duration: 2000, // Duración del Toast en milisegundos
      position: 'bottom', // Puedes ajustar la posición según tus preferencias
    });
    toast.present();
  }

  dismissToast() {
    this.toastController.dismiss();
  }

  private buildChat(): TabItem {
    return {
      id: 'chat',
      label: 'Chat',
      weight: 10,
      icon: 'chatbox-outline',
      disabled: false,
      show: true,
      action: () => {
        this.presentToast('Abriendo chat');
      },
    };
  }

  public async buildTabs(): Promise<TabItem[]> {
    const currentCountry = this.currentCountryService.get();
    console.log(`buildTabs -> Country: ${currentCountry.name}`);

    const tabs = [];

    const userData = await this.userDataService
      .getUserData()
      .pipe(
        filter((userData) => Object.keys(userData).length > 0),
        take(1)
      )
      .toPromise();

    switch (currentCountry.id) {
      case 'ar': {
        tabs.push(
          this.buildFacturar(userData),
          this.buildPagos(userData),
          this.buildEstadisticas(userData),
          this.buildChat()
        );
        break;
      }
      case 'pe': {
        tabs.push(
          this.buildFacturar(userData),
          this.buildPagos(userData),
          this.buildEstadisticas(userData),
          this.buildChat()
        );
        break;
      }
      case 'uy': {
        tabs.push(
          this.buildFacturar(userData),
          this.buildPagos(userData),
          this.buildEstadisticas(userData),
          this.buildChat()
        );
        break;
      }
    }

    return tabs.sort((a, b) => a.weight - b.weight);
  }
}
