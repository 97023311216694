import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-shaped',
  templateUrl: './header-shaped.component.html',
  styleUrls: ['./header-shaped.component.scss'],
})
export class HeaderShapedComponent {
  @Input() showLogo = false;
  @Input() showBackButton = false;
  @Input() text?: string;

  constructor(private location: Location) {}

  back(): void {
    this.location.back();
  }

  getClasses() {
    let classes = '';

    if (this.showLogo) {
      classes += ' header-shape-logo ';
    } else {
      classes += ' header-shape ';
    }

    return classes;
  }
}
