import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IObraSocial } from '../interfaces/obra-social.interface';
import { ResponseI } from '../interfaces/response.interface';
import { CurrentCountryService } from './current-country.service';

// TODO: Revisar cuando este el Backend

export interface IRecategorizacionFechas {
  recategorizacionFechaDesde: Date;
  recategorizacionFechaHasta: Date;
  recategorizacionEneroFechaDesde: Date;
  recategorizacionEneroFechaHasta: Date;
  recategorizacionJulioFechaDesde: Date;
  recategorizacionJulioFechaHasta: Date;
}
@Injectable({
  providedIn: 'root',
})
export class MonotributoService {
  get API_URL() {
    return this.currentCountryService.getApiUrl();
  }

  constructor(private http: HttpClient, private currentCountryService: CurrentCountryService) {}

  getCategoriesData() {
    return this.http.get(`${this.API_URL}/categoriamonotributo/list`);
  }

  getHealthInsuranceData() {
    return this.http
      .get<ResponseI<IObraSocial[]>>(`${this.API_URL}/obrasocial/list`)
      .pipe(map((res) => res?.data || []));
  }

  checkAlta(data) {
    return this.http.post(`${this.API_URL}/impuestos/monotributo/verificar-alta`, data);
  }

  altaMonotributo(data) {
    return this.http.post(`${this.API_URL}/impuestos/monotributo/alta`, data);
  }

  bajaMonotributo(data) {
    return this.http.post(`${this.API_URL}/impuestos/monotributo/baja`, data);
  }

  modificarMonotributo(data) {
    return this.http.post(`${this.API_URL}/impuestos/monotributo/modificacion`, data);
  }

  checkRecategorizacionMonotributo() {
    return this.http.get(`${this.API_URL}/impuestos/monotributo/verificar-recategorizacion`);
  }

  recategorizarMonotributo(data) {
    return this.http.post(`${this.API_URL}/impuestos/monotributo/recategorizar`, data);
  }

  pagar() {
    return this.http.post(`${this.API_URL}/impuestos/monotributo/pagar`, null);
  }

  reimputar() {
    return this.http.post(`${this.API_URL}/impuestos/monotributo/reimputar`, null);
  }

  getConstanciaInscripcion() {
    return this.http.post(`${this.API_URL}/tarea/afip/constanciaCuit`, null);
  }

  getFechasRecategorizacion(): Observable<boolean> {
    return this.http
      .get<ResponseI<IRecategorizacionFechas>>(`${this.API_URL}/impuestos/monotributo/fechas-recategorizacion`)
      .pipe(map((res) => this.checkDates(res.data)));
  }

  private checkDates(fechas: IRecategorizacionFechas) {
    const isBetweenDates = this.isInRange(fechas.recategorizacionFechaDesde, fechas.recategorizacionFechaHasta);
    if (isBetweenDates) {
      return true;
    }

    if (fechas.recategorizacionEneroFechaDesde && fechas.recategorizacionEneroFechaHasta) {
      const isBetweenDatesEnero = this.isInRange(
        fechas.recategorizacionEneroFechaDesde,
        fechas.recategorizacionEneroFechaHasta
      );
      if (isBetweenDatesEnero) {
        return true;
      }
    } else {
      const fecha = new Date();
      return fecha.getMonth() === 0 ? true : false;
    }

    if (fechas.recategorizacionJulioFechaDesde && fechas.recategorizacionJulioFechaHasta) {
      const isBetweenDatesJulio = this.isInRange(
        fechas.recategorizacionJulioFechaDesde,
        fechas.recategorizacionJulioFechaHasta
      );
      if (isBetweenDatesJulio) {
        return true;
      }
    } else {
      const fecha = new Date();
      return fecha.getMonth() === 6 ? true : false;
    }

    return false;
  }

  private isInRange(desde: Date, hasta: Date) {
    const today = new Date();
    const isBetween = (date, start, end) => {
      return date >= start && date <= end;
    };
    const isBetweenDates = isBetween(today, new Date(desde), new Date(hasta));
    if (isBetweenDates) {
      return true;
    }
  }
}
