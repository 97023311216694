import { Component, Input } from '@angular/core';
import { HomeSlide } from 'src/app/interfaces/home-slide.interface';
import { IUserData, UDEstateENUM } from 'src/app/services/user/user-data.interface';

@Component({
  selector: 'app-slides-ar',
  templateUrl: './slides.component.html',
  styleUrls: ['./slides.component.scss'],
})
export class SlidesArComponent implements HomeSlide {
  @Input() userData: IUserData;
  @Input() dateNow: Date;
  mobile: boolean;
  udeStates = UDEstateENUM;

  slideOpts = {
    initialSlide: 0,
    speed: 400,
  };
  async ngOnInit() {
    this.mobile = false;

    if (this.isMobile()) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }
  private isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
}
