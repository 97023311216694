import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Device } from '@capacitor/device';
import { MenuController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Country } from './interfaces/country.interface';
import { AuthService } from './services/auth.service';
import { CurrentCountryService } from './services/current-country.service';
import { MenuItem, MenuService } from './services/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  appVersion = environment.version;
  menuItems: MenuItem[];
  country: Country;
  salir: boolean;
  subscription: Subscription;
  mobile: boolean;
  menuweb: boolean;
  so: any;
  screenWidth: number;

  constructor(
    private menuService: MenuService,
    private authService: AuthService,
    private currentCountryService: CurrentCountryService,
    private menuController: MenuController,
    private menu: MenuController,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/ar/check-account') {
          this.menu.enable(false, 'mainMenu'); // Deshabilitar menú en login
        } else {
          this.menu.enable(true, 'mainMenu'); // Habilitar menú en otras páginas
        }
      }
    });
    this.checkScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
    this.checkScreenSize(); // Verifica el tamaño cuando se cambia el tamaño de la ventana
  }

  checkScreenSize() {
    if (this.screenWidth < 1080) {
      this.mobile = true; // Activa mobile si es menor a 1080px
    }
    if (this.screenWidth > 1080) {
      this.mobile = false; // Activa mobile si es menor a 1080px
    }
  }

  async ngOnInit() {
    this.menuweb = true;
    if (this.isMobile()) {
      this.mobile = true;
    } else {
      this.mobile = false;
      this.onResize();
    }
    this.subscription = new Subscription();

    this.subscription.add(
      this.authService.isLoggedIn
        .pipe(
          filter((loggedIn) => loggedIn),
          tap(() => this.logDeviceInfo()),
          tap(() => this.menuService.buildMenu().then((menu) => (this.menuItems = menu)))
        )
        .subscribe()
    );

    this.subscription.add(
      this.currentCountryService
        .getObservable()
        .pipe(tap((country) => (this.country = country)))
        .subscribe()
    );
    this.check();
  }

  check() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url === '' ||
          event.url === '/ar/contact/finish' ||
          event.url === '/recover' ||
          event.url === '/ar/check-account' ||
          event.url === '/login' ||
          event.url === '/register/form' ||
          event.url === '/ar/discount/finish' ||
          event.url === '/ar/servicios/ingresos-brutos/register/finish-congratulation' ||
          event.url === '/ar/servicios/monotributo/modificacion/finish-congratulation'
        ) {
          this.menu.enable(false, 'mainMenu'); // Deshabilitar menú en login
          this.menuweb = false;
        } else {
          this.menu.enable(true, 'mainMenu'); // Habilitar menú en otras páginas
          this.menuweb = true;
        }
      }
    });
    this.menuweb = false;
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  async logDeviceInfo() {
    const info = await Device.getInfo();
    console.log('logDeviceInfo:', info);
    this.so = info;
  }

  downloadApp() {
    const deviceInfo = this.so;

    if (deviceInfo.operatingSystem.toLowerCase() === 'mac' || deviceInfo.operatingSystem.toLowerCase() === 'ios') {
      window.location.href = 'https://apps.apple.com/ar/app/tributo-simple-monotributo/id1571573160';
    } else {
      window.location.href = 'https://play.google.com/store/apps/details?id=tributosimple.anfler.com.ar&hl=es_AR';
    }
  }

  async shareApp() {
    if (this.isMobile()) {
      this.menuController.close();
    } else {
      const url = 'http://www.tributosimple.com/argentina/compartir-descargar-app';
      window.open(url, '_system');
    }
  }

  private isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
}
