import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { getErrorMsg } from 'src/app/helpers/utils';
import { ApiClaveFiscalService } from 'src/app/services/api-clave-fiscal.service';
import { LoadingService } from 'src/app/services/misc/loading.service';
import { ToastService } from 'src/app/services/misc/toast.service';
import { TaxKeyState } from 'src/app/services/states/tax-key.state';
import { IUDJurisdiction } from 'src/app/services/user/user-data.interface';
import { UserDataService } from 'src/app/services/user/user-data.service';
import { GetTaxInfoComponent } from '../get-tax-info/get-tax-info.component';

@Component({
  selector: 'app-clave-fiscal-form',
  templateUrl: './clave-fiscal.component.html',
  styleUrls: ['./clave-fiscal.component.scss'],
})
export class ClaveFiscalComponent implements OnInit {
  private isShowPass = false;
  taxKey: string;

  @Input() code: string;
  @Input() jurisdiction: IUDJurisdiction;
  @Input() cuit: string;
  @Input() isNewCode = false;
  @Input() fromTaxFormRefreshUser = false;
  @Input() fromProfile = false;

  constructor(
    private modalCtrl: ModalController,
    private TaxState: TaxKeyState,
    private loadingS: LoadingService,
    private toast: ToastService,
    private apiClaveFiscalS: ApiClaveFiscalService,
    private userDataS: UserDataService
  ) {}

  ngOnInit() { console.log;}

  async create() {
    await this.loadingS.present();

    if (this.isNewCode) {
      const code = this.jurisdiction ? this.jurisdiction.codigo : this.code;
      this.apiClaveFiscalS.createNew(this.taxKey, code).subscribe(
        async ({ claves_fiscales }) => {
          console.log(claves_fiscales);

          this.loadingS.dismiss();

          if (this.fromProfile) {
            this.toast.present({
              message: 'Clave guardada con éxito',
            });
            this.dismiss(true);
          } else {
            this.TaxState.setCreatedKeys(claves_fiscales.map((el) => el.jurisdiccion.codigo));
            this.toast.present({
              message: 'Clave guardada con éxito',
            });

            this.dismiss();
          }
        },
        (err) => {
          this.loadingS.dismiss();
          console.error(err);
          this.toast.present({
            message: getErrorMsg(err),
            type: 'danger',
          });
          this.dismiss();
        }
      );
    } else {
      if (this.fromProfile) {
        const code = this.jurisdiction ? this.jurisdiction.codigo : this.code;
        this.apiClaveFiscalS.update(this.taxKey, code).subscribe(
          () => {
            this.loadingS.dismiss();
            this.toast.present({
              message: 'Clave guardada con éxito',
            });
            this.dismiss(true);
          },
          (err) => {
            this.loadingS.dismiss();
            console.error(err);
            this.toast.present({
              message: getErrorMsg(err),
              type: 'danger',
            });
            this.dismiss();
          }
        );
      }
    }
  }

  async dismiss(refresh?: boolean) {
    this.modalCtrl.dismiss({
      refresh,
      dismissed: true,
    });
  }

  async openInfo() {
    const modal = await this.modalCtrl.create({
      component: GetTaxInfoComponent,
    });

    return await modal.present();
  }

  hasShowPass() {
    return this.isShowPass;
  }

  showPass() {
    this.isShowPass = !this.isShowPass;
  }
}
