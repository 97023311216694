import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loadingRefId = [];

  constructor(private loadingCtrl: LoadingController) { }

  public async present(message?: string) {
    return await this.loadingCtrl.create({
      cssClass: 'ts-loader',
      message: message || 'Cargando...'
    }).then(async loader => {
      if (!this.loadingRefId.length) {
        this.loadingRefId.push(loader.id);
        loader.present();
      }
    });
  }

  async dismiss() {
    this.loadingRefId.forEach(refId => {
      try {
        this.loadingCtrl.dismiss(null, null, refId);
      } catch {
        console.log(refId);
      }
    });

    this.loadingRefId = [];
  }

}
