export enum CurrencyENUM {
  USD = 'USD',
  ARS = 'ARS',
  PEN = 'PEN',
  UYU = 'UYU',
}

export enum CurrencySymbolENUM {
  USD = 'USD',
  ARS = '$',
  PEN = 'S/',
  UYU = '$',
}
