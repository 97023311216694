import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { PlanItemI } from 'src/app/interfaces/plans.interface';
import { LoadingService } from 'src/app/services/misc/loading.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { IUserData } from 'src/app/services/user/user-data.interface';
import { UserDataService } from 'src/app/services/user/user-data.service';

@Component({
  selector: 'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss'],
})
export class PlanCardComponent implements OnInit {
  @Input() item: PlanItemI;

  userData: IUserData;
  loadedOK = false;

  constructor(
    private userDataService: UserDataService,
    private navigationService: NavigationService,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    this.userData = await this.userDataService.refreshUserInfo();
    this.loadedOK = true;
  }

  async purchase() {
    await this.loadingService.present();
    const navigationExtras: NavigationExtras = { state: { uusuarioTSID: this.userData.id, itemId: this.item.id } };

    this.navigationService.navigateRoot('/mp-checkout', navigationExtras);
    this.loadingService.dismiss();
  }

  async goHome() {
    this.navigationService.navigateRoot('/home');
  }
}
