import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ResponseI } from '../interfaces/response.interface';
import { API_URL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiNotificationsService {

  constructor(private http: HttpClient) {}

  markAsRead(data: any) {
    return this.http.put<ResponseI<any>>(`${API_URL}/messaging/leida`, data).pipe(map((res) => res?.data));
  }

  getNotifications(limit: number, offset: number) {
    return this.http
      .get<ResponseI<any>>(`${API_URL}/messaging/list?limit=${limit}&offset=${offset}`)
      .pipe(map((res) => res?.data || null));
  }
}
