import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { API_URL } from 'src/environments/environment';
import { IReqUsuarioTSUpdate } from '../interfaces/requests/requests.interfaces';
import { ResponseI } from '../interfaces/response.interface';
import { IActividadests } from '../interfaces/usuario-ts.interfaces';
import { CurrentCountryService } from './current-country.service';
import { IUserData } from './user/user-data.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiUsuarioTsService {
  get API_URL() {
    return this.currentCountryApiService.getApiUrl();
  }

  constructor(private http: HttpClient, private currentCountryApiService: CurrentCountryService) {}

  getActivitiests() {
    return this.http
      .get<ResponseI<IActividadests[]>>(`${API_URL}/usuarioTS/actividadests`)
      .pipe(map((res) => res?.data || []));
  }

  getInfo() {
    return this.http.get<ResponseI<any>>(`${API_URL}/usuarioTS/info`).pipe(map((res) => res?.data || null));
  }

  update(body: IReqUsuarioTSUpdate) {
    return this.http
      .put<ResponseI<IUserData>>(`${API_URL}/usuarioTS/update`, body)
      .pipe(map((res) => res?.data || null));
  }

  putInforme() {
    return this.http.put(`${API_URL}/usuarioTS/informe`, null);
  }

  putPlanContratado() {
    return this.http.put(`${API_URL}/usuarioTS/plan-contratado`, null);
  }

  plancontrado() {
    return this.http.post(`${API_URL}/planContratado/new`, null);
  }

  serviciocontable() {
    return this.http.put(`${API_URL}/trabajo/servicio-contable`, null);
  }

  anularSubscripcionDebitoAutomatico() {
    return this.http.post(`${API_URL}/pagoUsuarioTS/monotributo/anular-debito-automatico`, {});
  }

  postActividadprincipal(usuarioTSActividadTSID: number) {
    const body = {
      usuarioTSActividadTSID,
    };
    return this.http
      .post<ResponseI<IActividadests[]>>(`${API_URL}/usuarioTS/actividadprincipal`, body)
      .pipe(map((res) => res?.data || []));
  }

  updateCuit(cuit: string) {
    return this.http.put<ResponseI<any>>(`${API_URL}/usuarioTS/update-cuit`, { cuit });
  }

  getFacturacion() {
    return this.http.get<ResponseI<any>>(`${this.API_URL}/usuarioTS/facturacion`).pipe(map((res) => res?.data || null));
  }

  getVencimientos(mesAnio: string) {
    return this.http
      .get<ResponseI<any>>(`${this.API_URL}/vencimientos/list?mesAnio=${mesAnio}`)
      .pipe(map((res) => res?.data || null));
  }
}
