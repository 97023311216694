import { Injectable } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  private readonly TAG = 'BrowserService -';

  constructor(private inAppBrowser: InAppBrowser, private platform: Platform) {
    console.log(this.TAG, 'load');
  }

  open(url: string, target = '_self') {
    console.log(this.TAG, 'url: ', url);

    if (this.platform.is('hybrid')) {
      const browser = this.inAppBrowser.create(url, target, {
        location: 'no',
        clearcache: 'yes',
        zoom: 'no',
        hardwareback: 'no',
        hidden: 'yes',
        toolbar: 'yes',
        toolbarposition: 'top',
        toolbarcolor: '#ffffff',
        toolbartranslucent: 'no',
        closebuttoncaption: 'Cerrar',
        closebuttoncolor: '#0047af',
        hidenavigationbuttons: 'yes',
        usewkwebview: 'yes',
      });

      browser.show();

      return browser;
    } else {
      window.open(url, target);
      return null;
    }
  }
}
