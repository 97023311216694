import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SHOWERRORS } from 'src/environments/environment';
import { getErrorMsg } from '../../helpers/utils';
import { AuthService } from '../auth.service';
import { ToastService } from '../misc/toast.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService, private toastS: ToastService) { }

  private pendingReq = [];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = this.auth.getToken();
    let request = req;
    if (token) {
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError(async (error: HttpErrorResponse) => {
        const status = error.status;
        const reason = getErrorMsg(error);

        console.error('FROM INTERCEPTOR', status, reason);

        if (SHOWERRORS) {
          if(reason == 'SOAP Fault: soap:Server\nNo existe persona con ese Id\n'){
            console.error('Cuit inválido')
          }else{
            this.toastS.present({
              message: `FROM INTERCEPTOR:\nstatus: ${status}\nreason: ${reason}`,
              type: 'danger',
              duration: 6000,
              position: 'middle'
            });
          }
        }

        if (error.status === 401 && reason !== 'Contraseña Incorrecta') {
          this.pendingReq.push(next);
          const newToken = await this.auth.refreshToken();
          if (newToken) {
            if (SHOWERRORS) {
              this.toastS.present({
                message: 'FROM INTERCEPTOR:\nreason: Token refreshed',
                type: 'success',
                duration: 6000,
                position: 'middle'
              });
            }
            console.log(newToken);
            let newRequest = req;
            if (newToken) {
              newRequest = req.clone({
                setHeaders: {
                  authorization: `Bearer ${newToken}`,
                },
              });
            }
            return await this.pendingReq[0].handle(newRequest).toPromise();
          } else {
            this.auth.logout();
            return throwError(error);
          }
        } else {
          return await throwError(error).toPromise();
        }
      })
    );
  }
}
