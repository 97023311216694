import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent implements OnInit {
  @Input() msg: string;
  constructor(private modalController: ModalController) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit() {}

  close() {
    this.modalController.dismiss();
  }
}
