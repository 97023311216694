// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  text-align: left;
  font-weight: bold;
  font-size: 15px;
  margin: 5px;
}

.subtitle {
  text-align: left;
  margin: 5px;
}

ion-item.custom-select ion-label span {
  color: red;
}

ion-grid {
  padding: 10px 15px 5px 15px;
}

.header {
  margin-top: 10px;
}
.header p {
  margin-bottom: 0;
}

ion-item {
  --padding-top: 0;
  padding-top: 0;
  margin-bottom: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZvcm0tcmVhbC1hZGRyZXNzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsZ0JBQUE7RUFDQSxpQkFBQTtFQUNBLGVBQUE7RUFDQSxXQUFBO0FBQ0Y7O0FBRUE7RUFDRSxnQkFBQTtFQUNBLFdBQUE7QUFDRjs7QUFHRTtFQUNFLFVBQUE7QUFBSjs7QUFJQTtFQUNFLDJCQUFBO0FBREY7O0FBSUE7RUFDRSxnQkFBQTtBQURGO0FBR0U7RUFDRSxnQkFBQTtBQURKOztBQUtBO0VBQ0UsZ0JBQUE7RUFDQSxjQUFBO0VBQ0EsbUJBQUE7QUFGRiIsImZpbGUiOiJmb3JtLXJlYWwtYWRkcmVzcy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi50aXRsZSB7XG4gIHRleHQtYWxpZ246IGxlZnQ7XG4gIGZvbnQtd2VpZ2h0OiBib2xkO1xuICBmb250LXNpemU6IDE1cHg7XG4gIG1hcmdpbjogNXB4O1xufVxuXG4uc3VidGl0bGUge1xuICB0ZXh0LWFsaWduOiBsZWZ0O1xuICBtYXJnaW46IDVweDtcbn1cblxuaW9uLWl0ZW0uY3VzdG9tLXNlbGVjdCB7XG4gIGlvbi1sYWJlbCBzcGFuIHtcbiAgICBjb2xvcjogcmVkO1xuICB9XG59XG5cbmlvbi1ncmlkIHtcbiAgcGFkZGluZzogMTBweCAxNXB4IDVweCAxNXB4O1xufVxuXG4uaGVhZGVyIHtcbiAgbWFyZ2luLXRvcDogMTBweDtcblxuICBwIHtcbiAgICBtYXJnaW4tYm90dG9tOiAwO1xuICB9XG59XG5cbmlvbi1pdGVtIHtcbiAgLS1wYWRkaW5nLXRvcDogMDtcbiAgcGFkZGluZy10b3A6IDA7XG4gIG1hcmdpbi1ib3R0b206IDIwcHg7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/shared/form-real-address/form-real-address.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,WAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;AACF;;AAGE;EACE,UAAA;AAAJ;;AAIA;EACE,2BAAA;AADF;;AAIA;EACE,gBAAA;AADF;AAGE;EACE,gBAAA;AADJ;;AAKA;EACE,gBAAA;EACA,cAAA;EACA,mBAAA;AAFF;AACA,4kCAA4kC","sourcesContent":[".title {\n  text-align: left;\n  font-weight: bold;\n  font-size: 15px;\n  margin: 5px;\n}\n\n.subtitle {\n  text-align: left;\n  margin: 5px;\n}\n\nion-item.custom-select {\n  ion-label span {\n    color: red;\n  }\n}\n\nion-grid {\n  padding: 10px 15px 5px 15px;\n}\n\n.header {\n  margin-top: 10px;\n\n  p {\n    margin-bottom: 0;\n  }\n}\n\nion-item {\n  --padding-top: 0;\n  padding-top: 0;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
