import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

export enum TextFieldTypes {
  Text = 'text',
  Email = 'email',
  Password = 'password',
}

export const toBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const b64toBlob = (b64Data: any, contentType?: string, sliceSize = 512) => {
  if (contentType !== '') {
    contentType = b64Data.includes('data:application/') ? 'application/pdf' : 'image/jpeg';
  }

  if (b64Data.includes('data:image/') || b64Data.includes('data:application/')) {
    b64Data = b64Data.replace(/^(data:image|data:application)\/(png|jpeg|jpg|pdf);base64,/, '');
  }

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const blobToFile = (blob: Blob, fileName = `${Date.now()}`): File => {
  const b: any = blob;
  b.lastModifiedDate = new Date();
  b.name = fileName;
  return blob as File;
};

export const getErrorMsg = (err: any) => {
  const defaultMsg = 'Un error ha ocurrido, intente nuevamente.';
  try {
    const error = err?.error?.errors?.[0];

    if (!error) {
      return defaultMsg;
    }

    if (error.title === 'Missing token') {
      return 'No autenticado.';
    }

    return error.title;
  } catch {
    return defaultMsg;
  }
};

export function validarCUIT(sCUIT) {
  const iCoeficiente: Array<number> = [];
  const iValoresCUIT: Array<number> = [];
  let iSumaDeFactores: number;
  let iDigitoVerificador: number;
  let iCont: number;

  // Cargo los coeficientes
  iCoeficiente.push(5);
  iCoeficiente.push(4);
  iCoeficiente.push(3);
  iCoeficiente.push(2);
  iCoeficiente.push(7);
  iCoeficiente.push(6);
  iCoeficiente.push(5);
  iCoeficiente.push(4);
  iCoeficiente.push(3);
  iCoeficiente.push(2);

  // Tomo los numeros del CUIT
  iCont = 0;
  for (let i = 0; i < sCUIT.length; i++) {
    // Tomo los numeros del CUIT
    if (sCUIT.charCodeAt(i) >= 48 && sCUIT.charCodeAt(i) <= 57) {
      // Coloco el número en la posición correspondiente
      iCont = iCont + 1;
      iValoresCUIT.push(parseInt(sCUIT.charAt(i)));
    }
  }

  // Veo si están todos los dígitos
  if (iCont != 11) {
    return false;
  }

  // Calculo la suma de los factores
  iSumaDeFactores = 0;
  for (let i = 0; i < 10; i++) {
    // Calculo la suma de la multiplicacion del coeficiente y el valor
    iSumaDeFactores = iSumaDeFactores + iCoeficiente[i] * iValoresCUIT[i];
  }

  // Tomo el resto de la division de la suma con 11
  const iRestoDivision11 = iSumaDeFactores % 11;

  // Analizo ese resto
  if (iRestoDivision11 == 0) {
    // 'Si el resto es 0 el digito verificador es 0
    iDigitoVerificador = 0;
  } else if (iRestoDivision11 == 1) {
    // Si el resto es 1 el digito verificador es ...
    iDigitoVerificador = iValoresCUIT[10];
  } else {
    // en el resto de los casos el dígito verificador es ...
    iDigitoVerificador = 11 - iRestoDivision11;
  }

  // Finalmente veo si es vélido el CUIT
  if (iRestoDivision11 != 1 && iDigitoVerificador == iValoresCUIT[10]) {
    // Es válido
    return true;
  } else {
    // Es inválido
    return false;
  }
}

/**
 * Funcion para validar RUT (Uruguay)
 * https://github.com/brunogimenes/uruguay-rut-validator
 */
export const validarRUT = (rut: string) => {
  try {
    if (!rut || rut.length !== 12) {
      return false;
    }

    const digitC = Number.parseInt(rut.substring(11, 1));
    const rest = rut.substring(0, 11);

    let total = 0;
    let factor = 2;

    for (let i = 10; i >= 0; i--) {
      const n = Number.parseInt(rest.substring(i, 1));
      total += factor * n;

      if (factor === 9) {
        factor = 2;
      } else {
        factor++;
      }
    }

    let digitV = 11 - (total % 11);

    if (digitV === 11) {
      digitV = 0;
    } else if (digitV === 10) {
      digitV = 1;
    }

    return digitV === digitC;
  } catch {
    return false;
  }
};

export const validarCI = (ci: string) => {
  ci = ci.replace(/\D/g, '');

  const ciDigits = ci.split('').map(Number);
  const checkDigit = ciDigits.pop();
  let verifier: number;

  if (ciDigits.length === 6) {
    verifier = ciDigits.reduce((acc, digit, index) => acc + digit * [1, 2, 3, 4, 7, 6][index], 0) % 10;
  } else if (ciDigits.length === 7) {
    verifier = ciDigits.reduce((acc, digit, index) => acc + digit * [8, 1, 2, 3, 4, 7, 6][index], 0) % 10;
  } else {
    return false;
  }

  return verifier === checkDigit;
};

export const formatDate = (date: Date, format = 'yyyy-mm-dd') => {
  const map = {
    mm: ('0' + (date.getMonth() + 1)).slice(-2),
    dd: ('0' + date.getDate()).slice(-2),
    yyyy: date.getFullYear().toString(),
  };

  return format.replace(/mm|dd|yyyy/gi, (matched) => map[matched]);
};

export const findInvalidControlsRecursive = (formToInvestigate: UntypedFormGroup | UntypedFormArray): string[] => {
  const invalidControls: string[] = [];
  const recursiveFunc = (form: UntypedFormGroup | UntypedFormArray) => {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      if (control?.invalid) invalidControls.push(field);
      if (control instanceof UntypedFormGroup) {
        recursiveFunc(control);
      } else if (control instanceof UntypedFormArray) {
        recursiveFunc(control);
      }
    });
  };
  recursiveFunc(formToInvestigate);
  return invalidControls;
};

export const validateSunat = (event: any)  => {
  let key: any;

  if (event.type === 'paste') {
    key = event.clipboardData.getData('text/plain').toUpperCase()
  } else {
    key = event.keyCode;
    key = String.fromCharCode(key).toUpperCase()
  }

  const regex = /[A-Z0-9]|\./;

  if (!regex.test(key)) {
    event.returnValue = false;

    if (event.preventDefault) {
      event.preventDefault();
    }
  }
}
