import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { KeyboardService } from '../services/native/keyboard.service';

@Directive({
  selector: '[appKeyboardShowHide]'
})
export class KeyboardShowHideDirective implements OnInit, OnDestroy {

  private subscription: Subscription;

  constructor(private elementRef: ElementRef, private keyboardS: KeyboardService) { }

  ngOnInit() {
    this.subscription = this.keyboardS.getKeyboardStatus().subscribe(hide => {
      this.elementRef.nativeElement.style.display = hide ? 'none' : '';
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

}

