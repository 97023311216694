import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TaxKeyState {
  private _created_keys: string[] = [];

  get createdKeys() {
    return this._created_keys;
  }

  setCreatedKeys(data: string[]) {
    this._created_keys = data;
  }
}
