import { Injectable } from '@angular/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { ApiUserService } from '../api-user.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  private readonly TAG = 'PushNotificationService -';

  constructor(private platform: Platform, private apiUserS: ApiUserService) {
    console.log(this.TAG, 'load');
  }

  async initService() {
    if (this.platform.is('hybrid')) {
      try {
        await this.registerNotifications();
        this.addListeners();
      } catch (error) {
        console.error(this.TAG, 'Error initializing push notifications:', error);
      }
    }
  }

  closeService() {
    if (this.platform.is('hybrid')) {
      this.updateRegId('');
      this.clearListeners();
    }
  }

  private async addListeners() {
    console.log(this.TAG, 'setListeners');

    await PushNotifications.addListener('registration', token => {
      console.log(this.TAG, 'listener - registration:', token.value);
      this.updateRegId(token.value || '');
    });

    await PushNotifications.addListener('registrationError', err => {
      console.error(this.TAG, 'listener - registrationError:', err.error);
    });

    await PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log(this.TAG, 'listener - pushNotificationReceived:', notification);
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      console.log(this.TAG, 'listener - pushNotificationActionPerformed:', notification.actionId, notification.inputValue);
    });
  }

  private async registerNotifications() {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
  }

  private async getDeliveredNotifications() {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log(this.TAG, 'delivered notifications:', notificationList);
  }

  private clearListeners() {
    PushNotifications.removeAllListeners();
    PushNotifications.removeAllDeliveredNotifications();
  }

  private updateRegId(regId: string) {
    if (regId?.length > 0) {
      this.apiUserS.postRegId(regId).subscribe(
        response => console.log(this.TAG, 'RegId updated:', response),
        error => console.error(this.TAG, 'Error updating RegId:', error)
      );
    }
  }
}