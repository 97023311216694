import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { filter, take } from 'rxjs/operators';
import { IUserData } from 'src/app/services/user/user-data.interface';
import { UserDataService } from 'src/app/services/user/user-data.service';
import { ApiUsuarioTsService } from './api-usuario-ts.service';
import { AuthService } from './auth.service';

declare let ZohoSalesIQ: any;

@Injectable({
  providedIn: 'root',
})
export class ZohoService {
  userData: IUserData;

  constructor(
    private userDataService: UserDataService,
    private platform: Platform,
    usuarioService: ApiUsuarioTsService,
    private authService: AuthService
  ) {
    if (Capacitor.isNative) {
      // if (this.platform.is('ios')) {
      //   ZohoSalesIQ.init(environment.zoho.ios.appKey, environment.zoho.ios.accessKey);
      // } else if (this.platform.is('android')) {
      //   ZohoSalesIQ.init(environment.zoho.android.appKey, environment.zoho.android.accessKey);
      // }

      ZohoSalesIQ.setLanguage('es');
      ZohoSalesIQ.showLauncher(false);

      this.authService
        .getloggedIn()
        .pipe(
          filter((token) => token !== null),
          take(1)
        )
        .subscribe(() => {
          usuarioService.getInfo().subscribe((usuario) => {
            ZohoSalesIQ.setVisitorName(`${usuario.nombre} ${usuario.apellido}`);
            ZohoSalesIQ.setVisitorNameVisibility(true);
            ZohoSalesIQ.disablePreChatForms();
          });
        });
    }
  }

  async algunaFuncionAsincronica() {
    this.userData = await this.userDataService.refreshUserInfo();
  }



  showChat() {
    console.log('userDataPais:', this.userData.pais.code);

    if (this.isMobile()) {
      if (Capacitor.isNative) {
        ZohoSalesIQ.show();
      }
    } else {
      if (this.userData.pais.code === 'ar') {
        const url = 'https://tributosimple.com/argentina/contacto-wpp-web';
        window.open(url, '_system');
      }
      if (this.userData.pais.code === 'pe') {
        const url = 'https://tributosimple.com/peru/contacto-wpp-web';
        window.open(url, '_system');
      }
      if (this.userData.pais.code === 'uy') {
        const url = 'https://tributosimple.com/uruguay/contacto-wpp-web';
        window.open(url, '_system');
      }
    }
  }
  private isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
}
